import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

import type { IFormCustomerInfoData } from '../form-customer-info/form-customer-info.interfaces';
import type { IFormDataShippingAddress } from '../form-shipping-address/form-shipping-address.interfaces';

@Component({
  selector: 'app-form-test-buttons',
  templateUrl: './form-test-buttons.component.html',
  styleUrls: ['./form-test-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTestButtonsComponent {
  @Output() stepChange = new EventEmitter<1 | 2 | 3>();

  @Output() customerInfoChange = new EventEmitter<IFormCustomerInfoData>();

  @Output() shippingAddressChange =
    new EventEmitter<IFormDataShippingAddress>();

  @Output() printOnStagingChange = new EventEmitter<boolean>();

  // constructor() {}

  /**
   * filling the customer info form group with test data
   */
  public fillCustomerInfo(): void {
    this.customerInfoChange.emit({
      firstName: 'John',
      lastName: 'Doe',
      addressFirst: '354 Oyster Point Boulevard',
      addressSecond: '#1900',
      city: 'San Francisco',
      postalCode: '94080',
      country: 'US',
      state: 'CA',
      phone: '4842634645',
      email: 'test@mail.com',
      // email2: 'test@mail.com',
    });
  }

  // /**
  //  * filling the shipping address form group with test data
  //  */
  public fillShippingAddress(): void {
    this.shippingAddressChange.emit({
      firstName: 'Jane',
      lastName: 'Doe',
      addressFirst: '920 5th Ave',
      addressSecond: '#1900',
      city: 'Seattle',
      postalCode: '98104',
      country: 'US',
      state: 'WA',
      phone: '4842634645',
    });
  }
}
