import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { CaptureConsole, HttpClient } from '@sentry/integrations';
import { scrollToElement } from 'app/functions';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Logger } from './app/services/logger.service';
import pkg from '../package.json';

// const oldAlert = alert;

// window.alert = function () {
//   console.log(new Error().stack);
//   oldAlert.apply(window, arguments);
// };

if (environment.production) {
  Sentry.init({
    dsn: 'https://7dcc2358ebd941b085217f3922373bbc@o4505322006315008.ingest.sentry.io/4505322010509312',
    release: pkg.version,
    environment: environment.production ? 'production' : 'development',
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
      new HttpClient({
        failedRequestStatusCodes: [[400, 599]],
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  enableProdMode();
  Logger.enableProductionMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`, success))
  .catch((err) => console.error(err));

window.addEventListener('load', () => {
  const style = document.createElement('style');

  style.innerHTML = /* scss */ `
    @font-face {
      font-family: Rubik;
      font-weight: 300;
      src: url("assets/fonts/Rubik/Rubik-Light.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 300;
      font-style: italic;
      src: url("assets/fonts/Rubik/Rubik-LightItalic.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 400;
      src: url("assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 400;
      font-style: italic;
      src: url("assets/fonts/Rubik/Rubik-Italic.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 500;
      src: url("assets/fonts/Rubik/Rubik-Medium.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 500;
      font-style: italic;
      src: url("assets/fonts/Rubik/Rubik-MediumItalic.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 600;
      src: url("assets/fonts/Rubik/Rubik-SemiBold.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 600;
      font-style: italic;
      src: url("assets/fonts/Rubik/Rubik-SemiBoldItalic.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 700;
      src: url("assets/fonts/Rubik/Rubik-Bold.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 700;
      font-style: italic;
      src: url("assets/fonts/Rubik/Rubik-BoldItalic.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 800;
      src: url("assets/fonts/Rubik/Rubik-ExtraBold.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 800;
      font-style: italic;
      src: url("assets/fonts/Rubik/Rubik-ExtraBoldItalic.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 900;
      src: url("assets/fonts/Rubik/Rubik-Black.ttf") format("truetype");
      font-display: swap;
    }

    @font-face {
      font-family: Rubik;
      font-weight: 900;
      font-style: italic;
      src: url("assets/fonts/Rubik/Rubik-BlackItalic.ttf") format("truetype");
      font-display: swap;
    }
  `;

  document.head.appendChild(style);
});

const clickHandlerLog = new Logger('CLICK HANDLER');
/**
 * as we have updated our base href we need to fix anchor's logic
 */
window.addEventListener('click', (event) => {
  const log = clickHandlerLog;
  const { target } = event;

  if (target instanceof HTMLAnchorElement) {
    const href = target.getAttribute('href');

    if (href.startsWith('#') && href.length > 1) {
      event.preventDefault();

      // log.info('ANCHOR');

      const id = href.slice(1);
      const element = document.getElementById(href.slice(1));

      if (!element) {
        log.error(`element not found by id "${id}" in anchor`, target);
        return;
      }

      scrollToElement(element);
    }
  }
});

const ImageSetter = Image.prototype.__lookupSetter__('src');

Image.prototype.__defineSetter__(
  'src',
  function (this: typeof Image.prototype, src: string) {
    ImageSetter.bind(this)(src);

    if (src.startsWith('assets/')) {
      this.setAttribute('crossOrigin', 'anonymous');
    }
  },
);
