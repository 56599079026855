import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormStripeCustomerComponent } from './form-stripe-customer.component';
import { FormHeaderModule } from '../form-header/form-header.module';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [FormStripeCustomerComponent],
  imports: [CommonModule, FormHeaderModule, InputModule],
  exports: [FormStripeCustomerComponent],
})
export class FormStripeCustomerModule {}
