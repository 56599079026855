import type { AfterViewInit, OnInit } from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { slideY } from 'src/app/animations';
import { checkControlShowError } from 'src/app/functions';
import { Logger } from 'src/app/services/logger.service';
import uniqueId from 'lodash/uniqueId';
import lowerFirst from 'lodash/lowerFirst';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ESelectButtonTheme } from './components/select-button/enums';
import { StripeCardComponent } from './components/stripe-card/stripe-card.component';
import {
  EInputTheme,
  TYPE,
  TYPE_BUTTON,
  TYPE_CHECKBOX,
  TYPE_NUMBER,
  TYPE_PASSWORD,
  TYPE_SELECT,
  TYPE_STRIPE_CARD,
  TYPE_SUBMIT,
  TYPE_TEXT,
  TYPE_TEXTAREA,
  Value,
} from './interfaces';

const log = new Logger('InputComponent');

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true,
};

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  animations: [slideY()],
})
export class InputComponent
  implements OnInit, ControlValueAccessor, AfterViewInit
{
  @ViewChild(StripeCardComponent)
  public stripeCardComponent?: StripeCardComponent;

  @Input() public type: TYPE = TYPE_TEXT;

  @Input() public name?: string;

  @Input() public disabled: boolean = false;

  @Input() public placeholder: string = '';

  @Input() public label?: string;

  @Input() public rows: string | number = 12;

  @Input() public flat: boolean = false;

  @Input() public bordered: boolean = false;

  @Input() public error?: string;

  @Input() public theme = EInputTheme.default;

  @Input() set value(v: Value) {
    if (typeof v === 'object' || v !== this._value) {
      log.info('value set', v);
      this._value = v;
      this.onChange(v);
      this.valueChange.emit(v);
    }
  }

  get value(): Value {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<Value>();

  private _value: Value = '';

  private _idSubject = new BehaviorSubject<string | undefined>(undefined);

  public idObservable = this._idSubject
    .asObservable()
    .pipe(filter((id) => !!id));

  get id() {
    return this._idSubject.value;
  }

  public readonly TYPE_TEXT = TYPE_TEXT;

  public readonly TYPE_NUMBER = TYPE_NUMBER;

  public readonly TYPE_PASSWORD = TYPE_PASSWORD;

  public readonly TYPE_TEXTAREA = TYPE_TEXTAREA;

  public readonly TYPE_STRIPE_CARD = TYPE_STRIPE_CARD;

  public readonly TYPE_SELECT = TYPE_SELECT;

  public readonly TYPE_CHECKBOX = TYPE_CHECKBOX;

  public readonly TYPE_BUTTON = TYPE_BUTTON;

  public readonly TYPE_SUBMIT = TYPE_SUBMIT;

  public readonly EInputTheme = EInputTheme;

  // public readonly ESelectButtonTheme = ESelectButtonTheme;

  public control?: FormControl;

  constructor(private _injector: Injector, private _cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const ngControl = this._injector.get(NgControl);

    if (ngControl) {
      this.control = ngControl.control as FormControl;
      this._cdr.detectChanges();
    }
  }

  get selectButtonTheme(): ESelectButtonTheme {
    switch (this.theme) {
      case EInputTheme.alt:
        return ESelectButtonTheme.alt;
      default:
        return ESelectButtonTheme.default;
    }
  }

  get showError(): boolean {
    if (!this.control) return false;

    return checkControlShowError(this.control);
  }

  get withOwnLabel(): boolean {
    return this.type === TYPE_CHECKBOX;
  }

  writeValue(value: Value): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange(value: Value) {}

  public onTouched() {}

  ngOnInit(): void {
    this._idSubject.next(this._getId());
  }

  private _getId(id: number | string = uniqueId()): string {
    return `${lowerFirst(this.constructor.name)}_${id}`;
  }
}
