// import format from 'date-fns-tz/format';
// import { Logger } from '@src/logger';
import isWithinInterval from 'date-fns/isWithinInterval';
import addDays from 'date-fns/addDays';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import addMilliseconds from 'date-fns/addMilliseconds';
import { rangesIntersection } from '../utils';
import { LIMIT_BOOKS_TO_ORDER } from '../constants';
import type { IDateInterval } from '../interfaces';
import type { TRegionName } from '../shipping/interfaces';
import { PRODUCTION_TIME_ZONE } from './constants';
import type {
  ICycle,
  IException,
  IProduction,
  IProductionFB,
  IShipping,
  TShippingMap,
  TShippingMethodsMap,
} from './interfaces';

export const toProduction = ({
  cycles,
  exceptions,
  shippingMethodsMap,
}: IProductionFB): IProduction => ({
  cycles,
  exceptions: exceptions.map(({ start, end }) => ({
    start: start.toDate(),
    end: end.toDate(),
  })),
  shippingMethodsMap,
});

export const getStartOfDay = (_today = new Date()) => {
  const today = utcToZonedTime(_today, PRODUCTION_TIME_ZONE);

  return zonedTimeToUtc(
    new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    PRODUCTION_TIME_ZONE,
  );
};

/** @throws { Error } when dayOfWeek is NaN */
export const getDayOfWeek = (_today = new Date()) => {
  const dayOfWeek = parseInt(
    formatInTimeZone(_today, PRODUCTION_TIME_ZONE, 'i'),
    10,
  );

  if (Number.isNaN(dayOfWeek)) {
    throw new Error(`dayOfWeek is ${dayOfWeek}`);
  }

  return dayOfWeek as 1 | 2 | 3 | 4 | 5 | 6 | 7;
};

/** @throws { Error } when cycle not found */
export const getCycle = (
  cycles: ICycle[],
  _today = new Date(),
  _dayOfWeek = getDayOfWeek(_today),
) => {
  const cycle = cycles.find(
    ({ start, end }) => _dayOfWeek >= start && _dayOfWeek <= end,
  );

  if (!cycle) {
    throw new Error(
      `cycle not found by ${_dayOfWeek} in ${JSON.stringify(cycles)}`,
    );
  }

  return cycle;
};

/** @throws { Error } */
export const getProductionInterval = (
  cycles: ICycle[],
  _today = new Date(),
) => {
  const startOfDay = getStartOfDay(_today);
  const dayOfWeek = getDayOfWeek(_today);
  const cycle = getCycle(cycles, _today, dayOfWeek);

  return {
    start: addDays(startOfDay, cycle.end - dayOfWeek + 1),
    end: addDays(startOfDay, cycle.prodEnd - dayOfWeek),
  };
};

const findException = (production: IDateInterval, exceptions: IException[]) =>
  exceptions.find(
    (_exception) =>
      isWithinInterval(_exception.start, production) ||
      isWithinInterval(_exception.end, production) ||
      isWithinInterval(production.start, _exception) ||
      isWithinInterval(production.end, _exception),
  );

/** MUTATES `interval.end` */
const applyException = (interval: IDateInterval, exception: IException) => {
  const eStart = exception.start.getTime();
  const eEnd = exception.end.getTime();
  const pStart = interval.start.getTime();
  const pEnd = interval.end.getTime();

  let diffTime: number;

  if (eStart > pStart) {
    diffTime = eEnd - eStart;
  } else if (eEnd > pEnd) {
    diffTime = eEnd - pStart;
  } else {
    const [start, end] = rangesIntersection(
      [eStart, eEnd],
      [pStart, pEnd],
    ).sort();

    diffTime = end - start;
  }

  interval.end = new Date(pEnd + diffTime);
};

/** MUTATES `interval.end` */
const applyExceptions = (interval: IDateInterval, exceptions: IException[]) => {
  const _exceptions: IException[] = [...exceptions];

  while (true) {
    /** {@link https://github.com/date-fns/date-fns/issues/366 isBetween example} */
    const exception = findException(interval, _exceptions);

    if (!exception) break;

    applyException(interval, exception);

    _exceptions.splice(_exceptions.indexOf(exception), 1);
  }
};

/** counts weekends in @param interval including start and end */
const countWeekends = (
  interval: IDateInterval,
  exceptions: IException[],
  dowArr: ReturnType<typeof getDayOfWeek>[] = [6, 7],
) => {
  const { start, end } = interval;

  const days = eachDayOfInterval({
    start: utcToZonedTime(start, PRODUCTION_TIME_ZONE),
    end: utcToZonedTime(end, PRODUCTION_TIME_ZONE),
  });

  const count = days.reduce((acc, day) => {
    const _day = addMilliseconds(zonedTimeToUtc(day, PRODUCTION_TIME_ZONE), 1);

    const exception = findException(
      {
        start: _day,
        end: _day,
      },
      exceptions,
    );

    if (exception) return acc;

    const dow = getDayOfWeek(zonedTimeToUtc(day, PRODUCTION_TIME_ZONE));

    if (dowArr.includes(dow)) {
      acc += 1;
    }
    return acc;
  }, 0);

  return count;
};

/** MUTATES `interval.end` */
const _applyWeekends = (interval: IDateInterval, count: number) => {
  interval.end = addDays(interval.end, count);
};

/** MUTATES `interval.end` */
const applyWeekends = (
  interval: IDateInterval,
  exceptions: IException[],
  dowArr: ReturnType<typeof getDayOfWeek>[] = [6, 7],
) => {
  /** private interval to count weekends from */
  const _interval = { ...interval };

  while (true) {
    const count = countWeekends(_interval, exceptions, dowArr);

    if (count === 0) break;

    _applyWeekends(interval, count);

    /** update private interval */
    _interval.start = addDays(_interval.end, 1);
    _interval.end = interval.end;
  }
};

const applyWeekendsAndExceptions = (
  interval: IDateInterval,
  exceptions: IException[],
  dowArr: ReturnType<typeof getDayOfWeek>[] = [6, 7],
) => {
  /** private interval to count from */
  const _interval = { ...interval };
  const _exceptions: IException[] = [...exceptions];

  while (true) {
    /** {@link https://github.com/date-fns/date-fns/issues/366 isBetween example} */
    const exception = findException(_interval, _exceptions);

    if (exception) {
      applyException(interval, exception);
      _exceptions.splice(_exceptions.indexOf(exception), 1);
      // increase private interval length
      _interval.end = interval.end;
    }

    // count weekends from all of the interval including extended by exception and excluded excepted
    const weekendsCount = countWeekends(_interval, exceptions, dowArr);
    // exclude excepted weekends
    // - (exception ? countWeekends(exception) : 0);

    if (weekendsCount > 0) {
      _applyWeekends(interval, weekendsCount);
    }

    if (weekendsCount < 1 && !exception) break;

    /** update private interval */
    _interval.start = addDays(_interval.end, 1);
    _interval.end = interval.end;
  }
};

/** @throws { Error } */
export const getEndOfCycleDate = (cycles: ICycle[], _today = new Date()) => {
  const startOfDay = getStartOfDay(_today);
  const dayOfWeek = getDayOfWeek(_today);
  const cycle = getCycle(cycles, _today, dayOfWeek);

  return addDays(startOfDay, cycle.end - dayOfWeek);
};

/** @throws { Error } */
export const getExpectedShippingDate = (
  cycles: ICycle[],
  exceptions: IException[],
  _today = new Date(),
) => {
  const production = getProductionInterval(cycles, _today);

  const exception = {
    start: production.end,
    end: production.end,
  };

  // include exception
  applyExceptions(production, exceptions);

  // update enception end when exceptions are applied
  exception.end = production.end;

  // include weekends to the exception difference
  applyWeekends(exception, exceptions);

  // updte production when weekends are applied
  production.end = exception.end;

  return production.end;
};

// * EXPECTED ARRIVAL DATE
const _getExpectedArrivalDateFormat = (
  expectedShippingDate: Date,
  shippingDays: number,
  exceptions: IException[],
) => {
  const expectedArrivalDate = addDays(expectedShippingDate, shippingDays);
  const shipping = {
    start: expectedShippingDate,
    end: expectedArrivalDate,
  };

  // if sat and/or sun included in shipping time we need to add it to final results
  applyWeekendsAndExceptions(shipping, exceptions);

  return shipping.end;
};

export function getExpectedArrivalDate<
  T extends Record<string, IShipping>,
  V extends keyof T,
>(
  expectedShippingDate: Date,
  region: V,
  shippingMap: T,
  exceptions: IException[],
): T[V]['days'] extends [number, number]
  ? [Date, Date]
  : T[V]['days'] extends number
  ? [Date]
  : [Date, Date] | [Date];

/** @throws { Error } */
export function getExpectedArrivalDate<
  T extends Record<string, IShipping>,
  V extends keyof T,
>(
  expectedShippingDate: Date,
  region: V,
  shippingMap: T,
  exceptions: IException[],
): [Date, Date] | [Date] {
  const { days } = shippingMap[region];

  if (!days) throw new Error(`shippingMap item not found by ${String(region)}`);

  const _format = _getExpectedArrivalDateFormat;

  if (days instanceof Array) {
    const [from, to] = days;

    return [
      _format(expectedShippingDate, from, exceptions),
      _format(expectedShippingDate, to, exceptions),
    ];
  }

  return [_format(expectedShippingDate, days, exceptions)];
}

// * VALIDATIONS
export const validateCycles = (cycles: ICycle[]) => {
  const errors: string[] = [];

  // * should not be empty
  if (!cycles?.[0]) {
    errors.push('Should not be empty.');
  }

  // * should start from 1
  if (cycles[0].start !== 1) {
    errors.push('Cycle 1 - Start should be 1.');
  }

  // * should finish with 7
  if (cycles[cycles.length - 1].end !== 7) {
    errors.push(`Cycle ${cycles.length} - End should be 7.`);
  }

  // * end should be same or more than start
  const invalidStart = cycles.find((item) => item.end < item.start);

  if (invalidStart) {
    errors.push(
      `Cycle ${
        cycles.indexOf(invalidStart) + 1
      } - End should be the same or more than Start.`,
    );
  }

  // * production end should be more than end
  const invalidEnd = cycles.find((item) => item.prodEnd < item.end);

  if (invalidEnd) {
    errors.push(
      `Cycle ${
        cycles.indexOf(invalidEnd) + 1
      } - Production End should be more than End.`,
    );
  }

  // * should go in sequence
  const overlaps = cycles.find((item, i, arr) => {
    const prev = arr[i - 1];

    if (!prev) return false;

    if (item.start - 1 !== prev.end) return true;

    return false;
  });

  if (overlaps) {
    errors.push(
      `Cycle ${
        cycles.indexOf(overlaps) + 1
      } - Start should be after the previous cycle End.`,
    );
  }

  return errors;
};

export const validateExceptions = (exceptions: IException[]) => {
  const errors: string[] = [];

  // * end should be same or more than start
  const invalidStart = exceptions.find((item) => item.end < item.start);

  if (invalidStart) {
    errors.push(
      `Exception ${
        exceptions.indexOf(invalidStart) + 1
      } - End should be the same or more than Start.`,
    );
  }

  // * should go in sequence
  const overlaps = exceptions.find((item, i, arr) => {
    const prev = arr[i - 1];

    if (!prev) return false;

    if (item.start.getTime() < prev.end.getTime()) return true;

    return false;
  });

  if (overlaps) {
    errors.push(
      `Exception ${
        exceptions.indexOf(overlaps) + 1
      } - Start should be after the previous exception End.`,
    );
  }

  return errors;
};

export const validateShippingMap = (shippingMap: TShippingMap) => {
  const errors: string[] = [];
  const regions = Object.keys(shippingMap) as TRegionName[];

  // * cannot be unset
  const unset = regions.find((region) => {
    const { days, prices } = shippingMap[region];

    if (!days) return true;

    if (prices.length === LIMIT_BOOKS_TO_ORDER) {
      prices.find((price) => !price);
    } else {
      return true;
    }

    return false;
  });

  if (unset) {
    errors.push(`Region ${unset} is not validly set.`);
  }

  // * first value cannot be greater the second
  const invalidTo = regions.find((region) => {
    const { days } = shippingMap[region];

    if (days instanceof Array && days[0] > days[1]) {
      return true;
    }

    return false;
  });

  if (invalidTo) {
    errors.push(`Region ${invalidTo} has "from" greater than "to".`);
  }

  return errors;
};

export const validateShippingMethodsMap = (
  shippingMethodsMap: TShippingMethodsMap,
) => {
  const errors: string[] = [];

  for (const method in shippingMethodsMap) {
    const shippingMap = shippingMethodsMap[method as keyof TShippingMethodsMap];

    const _errors = validateShippingMap(shippingMap);

    errors.push(..._errors.map((err) => `For method "${method}": ${err}`));
  }

  return errors;
};
