import {
  days,
  getFilledNumbersArray,
  months,
} from 'src/app/shared/timepicker-select/timepicker-select.data';
import {
  BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
  BOOK_PRICE_WHOS_BIRTHDAY_TOMORROW,
  BOOK_PRICE_WHOS_BIRTHDAY_TOMORROW_HARD_COVER,
  INPUT_NAME_AGE_TO_BE,
  INPUT_NAME_CHARACTER,
  INPUT_NAME_GENDER,
  INPUT_NAME_KIDS_BIRTHDAY_DAY,
  INPUT_NAME_KIDS_BIRTHDAY_MONTH,
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_LANGUAGE,
  INPUT_NAME_SEASON,
  INPUT_NAME_WHOS_CELEBRATING,
  INPUT_VALUE_ANIMALS,
  INPUT_VALUE_DINOS,
  INPUT_VALUE_ENGLISH,
  INPUT_VALUE_GENDER_BOY,
  INPUT_VALUE_GENDER_GIRL,
  INPUT_VALUE_JAPANESE,
  INPUT_VALUE_MONSTERS,
  INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
  INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
  INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
  INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
} from '@shared/book/constants';
import {
  getBookNameEnValidatorList,
  getBookNameValidatorList,
} from 'src/app/validators';
import {
  GET_BOOK_NAME_ERROR,
  GET_BOOK_NAME_ERROR_EN,
} from 'src/app/validators.constants';
import {
  ALIAS_TO_NAME_MAP,
  GENDER_TO_CHARACTER_SD_MAP,
} from '@shared/book/maps';
import type { TGender, TLanguage } from '@shared/book/interfaces';
import { languageArr } from '@shared/book/data';
import enUS from '@shared/translations/en-US.json';
import type { IBook } from './types';
import { getBookTextInputData } from './inputs/text/utils';
import {
  getBookRadioInputData,
  getBookRadioInputOptionData,
} from './inputs/radio/utils';
import { getBookPriceData } from './prices/utils';
import {
  getBookSelectInputData,
  getBookSelectInputOptionData,
} from './inputs/select/utils';
import { EBookBannerTextColor } from './banner/types';
import { EBookDescriptionTextItemType } from './desicription/types';
import {
  getBookRadioImageInputData,
  getBookRadioImageInputOptionData,
} from './inputs/radioImage/utils';
import { bookInputShowWhen, bookInputValidatorWhen } from './inputs/utils';
import { PROPERTY_PURPOSE_BIRTHDAY } from './properties/constants';

/**
 * * validatorWhen and showWhen are set inside IBook['inputs']
 * * by mutation funtions `bookInputValidatorWhen` and `bookInputShowWhenCheck`
 * * to make it possible to assign inputs between each other
 */

const WhosBirthdayTomorrowInputsKidsNameValidator = getBookNameValidatorList(7);
const WhosBirthdayTomorrowInputsKidsNameValidatorEn =
  getBookNameEnValidatorList(12);

export const WhosBirthdayTomorrowInputsKidsName = getBookTextInputData({
  label: 'First Name',
  // placeholder: 'ひらがな7文字まで',
  get placeholder() {
    return this.validator === WhosBirthdayTomorrowInputsKidsNameValidatorEn
      ? ('Up to 12 characters EN' as const)
      : ('Up to 7 characters' as const);
  },
  // value: '',
  name: INPUT_NAME_KIDS_NAME,
  // will be added in inputs array
  // validator: getBookNameValidatorList(7),
  get error() {
    return this.validator === WhosBirthdayTomorrowInputsKidsNameValidatorEn
      ? GET_BOOK_NAME_ERROR_EN(12)
      : GET_BOOK_NAME_ERROR(7);
  },
} as const);

export const WhosBirthdayTomorrowInputsGender = getBookRadioInputData({
  label: 'Gender',
  options: [
    getBookRadioInputOptionData({
      label: 'Boy',
      // imgSrc: 'assets/images/radio-boy-2.png',
      value: INPUT_VALUE_GENDER_BOY,
    }),
    getBookRadioInputOptionData({
      label: 'Girl',
      // imgSrc: 'assets/images/radio-girl-2.png',
      value: INPUT_VALUE_GENDER_GIRL,
    }),
  ],
  // value: INPUT_VALUE_GENDER_BOY,
  name: INPUT_NAME_GENDER,
});

export const WhosBirthdayTomorrowInputsLanguage = getBookSelectInputData({
  label: 'Language',
  placeholder: '',
  options: languageArr.map((lang) =>
    getBookSelectInputOptionData({ label: enUS[lang], value: lang }),
  ),
  value: INPUT_VALUE_ENGLISH,
  name: INPUT_NAME_LANGUAGE,
} as const);

export const WhosBirthdayTomorrowInputsCharacterBoy =
  getBookRadioImageInputData({
    label: 'Please choose child’s avatar.',
    options: GENDER_TO_CHARACTER_SD_MAP[INPUT_VALUE_GENDER_BOY].map(
      (value, index) =>
        getBookRadioImageInputOptionData({
          imgSrc: `assets/images/wbt-characters/boy/${index + 1}.png`,
          value,
        }),
    ),
    // value: '',
    name: INPUT_NAME_CHARACTER,
  } as const);

export const WhosBirthdayTomorrowInputsCharacterGirl =
  getBookRadioImageInputData({
    label: 'Please choose child’s avatar.',
    options: GENDER_TO_CHARACTER_SD_MAP[INPUT_VALUE_GENDER_GIRL].map(
      (value, index) =>
        getBookRadioImageInputOptionData({
          imgSrc: `assets/images/wbt-characters/girl/${index + 1}.png`,
          value,
        }),
    ),
    // value: '',
    name: INPUT_NAME_CHARACTER,
  } as const);

export const WhosBirthdayTomorrowInputsKidsBirthdayMonth =
  getBookSelectInputData({
    label: 'Birthday',
    placeholder: 'mm',
    options: months.map((month) =>
      getBookSelectInputOptionData({ label: month, value: month }),
    ),
    // value: '',
    name: INPUT_NAME_KIDS_BIRTHDAY_MONTH,
  });

export const WhosBirthdayTomorrowInputsKidsBirthdayDay = getBookSelectInputData(
  {
    label: '',
    placeholder: 'dd',
    options: days.map((day) =>
      getBookSelectInputOptionData({ label: day, value: day }),
    ),
    // value: '',
    name: INPUT_NAME_KIDS_BIRTHDAY_DAY,
  },
);

export const WhosBirthdayTomorrowInputsAgeToBe = getBookSelectInputData({
  label: 'Soon to be (age)',
  placeholder: '1~9yo',
  options: getFilledNumbersArray(1, 9, 1).map((year) =>
    getBookSelectInputOptionData({ label: year, value: year }),
  ),
  // value: '',
  name: INPUT_NAME_AGE_TO_BE,
});

export const WhosBirthdayTomorrowInputsWhosCelebrating = getBookRadioInputData({
  label: 'Who is celebrating the hero’s birthday?',
  options: [
    getBookRadioInputOptionData({
      label: 'Animals',
      imgSrc: 'assets/images/book2-animals.png',
      imgExpandTopPx: 13,
      imgExpandRightPx: 13,
      value: INPUT_VALUE_ANIMALS,
    }),
    getBookRadioInputOptionData({
      label: 'Dinosaurs',
      imgSrc: 'assets/images/book2-dinos.png',
      imgExpandTopPx: 13,
      imgExpandRightPx: 13,
      value: INPUT_VALUE_DINOS,
    }),
    getBookRadioInputOptionData({
      label: 'Monsters',
      imgSrc: 'assets/images/book2-monsters.png',
      imgExpandTopPx: 13,
      imgExpandRightPx: 13,
      value: INPUT_VALUE_MONSTERS,
    }),
  ],
  value: INPUT_VALUE_ANIMALS,
  name: INPUT_NAME_WHOS_CELEBRATING,
});

export const WhosBirthdayTomorrowInputsSeason = getBookRadioInputData({
  label: '',
  placeholder: '',
  options: [
    getBookRadioInputOptionData({
      label: INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
      imgSrc: 'assets/images/season-step/b2_spring.png',
      imgExpandTopPx: 21,
      imgExpandRightPx: 35,
      imgExpandBottomPx: 5,
      value: INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
    }),
    getBookRadioInputOptionData({
      label: INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
      imgSrc: 'assets/images/season-step/b2_summer.png',
      imgExpandTopPx: 21,
      imgExpandRightPx: 35,
      imgExpandBottomPx: 5,
      value: INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
    }),
    getBookRadioInputOptionData({
      label: INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
      imgSrc: 'assets/images/season-step/b2_autumn.png',
      imgExpandTopPx: 21,
      imgExpandRightPx: 35,
      imgExpandBottomPx: 5,
      value: INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
    }),
    getBookRadioInputOptionData({
      label: INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
      imgSrc: 'assets/images/season-step/b2_winter.png',
      imgExpandTopPx: 21,
      imgExpandRightPx: 35,
      imgExpandBottomPx: 5,
      value: INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
    }),
  ],
  // value: '',
  name: INPUT_NAME_SEASON,
});

// * mutations
bookInputValidatorWhen(WhosBirthdayTomorrowInputsKidsName, [
  {
    validator: WhosBirthdayTomorrowInputsKidsNameValidatorEn,
    showWhen: [
      {
        inputToCheck: WhosBirthdayTomorrowInputsLanguage,
        condition: (language: TLanguage) => language === INPUT_VALUE_ENGLISH,
      },
    ],
  },
  {
    validator: WhosBirthdayTomorrowInputsKidsNameValidator,
    showWhen: [
      {
        inputToCheck: WhosBirthdayTomorrowInputsLanguage,
        condition: (language: TLanguage) => language === INPUT_VALUE_JAPANESE,
      },
    ],
  },
]);

bookInputShowWhen(
  WhosBirthdayTomorrowInputsCharacterBoy,
  WhosBirthdayTomorrowInputsGender,
  (gender: TGender) => gender === INPUT_VALUE_GENDER_BOY,
);

bookInputShowWhen(
  WhosBirthdayTomorrowInputsCharacterGirl,
  WhosBirthdayTomorrowInputsGender,
  (gender: TGender) => gender === INPUT_VALUE_GENDER_GIRL,
);

const WhosBirthdayTomorrow: IBook = {
  caption: ALIAS_TO_NAME_MAP[BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW],
  summary: [
    'Make birthday planning a blast with this customizable book! Follow along as the characters prepare for your child’s big day and enjoy the chaos of party planning.',
  ],
  alias: BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
  prices: [
    ['soft', getBookPriceData(BOOK_PRICE_WHOS_BIRTHDAY_TOMORROW)],
    ['hard', getBookPriceData(BOOK_PRICE_WHOS_BIRTHDAY_TOMORROW_HARD_COVER)],
  ],
  photos: [
    'assets/images/bk2-carousel01-EN.jpg',
    'assets/images/bk2-carousel02-EN.jpg',
    'assets/images/bk2-carousel03-EN.jpg',
    'assets/images/bk2-carousel04-EN.jpg',
  ],
  menuPhoto: 'assets/images/bk2-sample-EN.png',
  previewPhoto: 'assets/images/books02-EN.png',
  properties: {
    purpose: [PROPERTY_PURPOSE_BIRTHDAY],
    age: {
      start: 1,
      end: 5,
    },
  },
  notice: {
    caption: 'Best Birthday Book!',
    description:
      'Make birthday planning a blast with this customizable book! Follow along as the characters prepare for your child’s big day and enjoy the chaos of party planning.',
    points: [
      {
        imgSrc: 'assets/images/birthday-notice-point-1.png',
        caption: "Best for 1~5 year old's",
      },
      {
        imgSrc: 'assets/images/birthday-notice-point-2.png',
        caption: 'Birthday Book',
      },
    ],
  },
  points: [
    {
      caption: 'What kind of customization can I make?',
      description:
        '<ul>' +
        "<li>Child's name</li>" +
        "<li>Child's story avatar</li>" +
        '<li>Characters in the story</li>' +
        '<li>Dedication message</li>' +
        "<li>Child's photo</li>" +
        '<li>Birthday balloons and candles</li>' +
        '</ul>',
    },
    {
      caption: 'What is the story of the book？',
      description:
        'It’s the night before your birthday and time for bed, but there’s a commotion outside. ‘Tomorrow is the birthday of our special child,’ someone says, and all your friends begin preparing for a fantastic party!',
    },
    {
      caption: 'About format and quality of the book.',
      description:
        'Every single book we make is print-to-order original picture book. We use thick, high-quality environment-friendly paper.\n' +
        '<ul>' +
        '<li><b>28 pages</b></li>' +
        '<li><b>8.3” x 8.3” (21cm x 21cm)</b></li>' +
        '<li><b>Printed in the USA</b></li>' +
        '</ul>',
    },
  ],
  inputs: [
    WhosBirthdayTomorrowInputsKidsName,
    WhosBirthdayTomorrowInputsKidsBirthdayMonth,
    WhosBirthdayTomorrowInputsKidsBirthdayDay,
    WhosBirthdayTomorrowInputsAgeToBe,
    WhosBirthdayTomorrowInputsGender,
    WhosBirthdayTomorrowInputsLanguage,
    WhosBirthdayTomorrowInputsCharacterBoy,
    WhosBirthdayTomorrowInputsCharacterGirl,
    WhosBirthdayTomorrowInputsWhosCelebrating,
  ],
  banner: {
    imgSrc: 'assets/images/book2-banner-desktop.png',
    imgSrcLtMd: 'assets/images/book2-banner-mobile.png',
    text: 'Tomorrow is a special day - it’s your child’s birthday!<br>Let’s get ready for the party!',
    textColor: EBookBannerTextColor.dark,
  },
  description: [
    {
      imgSrc: 'assets/images/bk2-image01-EN.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: 'Get ready for a BIG surprise tomorrow!',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'A big surprise awaits your child tomorrow!',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'Through the story, your child learns that a special birthday party is being planned just for them.',
        },
      ],
    },
    {
      imgSrc: 'assets/images/bk2-image02-EN.gif',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: 'Ready for the best party ever?',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'Who will help plan the best birthday party ever? Mischievous animals, funny dinosaurs, or cute monsters?',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'Don’t worry, this won’t be a boring party - let’s invite everyone and see what we can create together!',
        },
      ],
    },
  ],
  review: {
    text: [
      "This was a gift for my son’s 3rd birthday. He loves the animal characters and seeing his photo at the end of the story. Props to whoever came up with the story.",
    ],
  },
  reviews: [],
};

export default WhosBirthdayTomorrow;
