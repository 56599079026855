import {
  BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  BOOK_PRICE_WELCOME_TO_THE_WOLD,
  BOOK_PRICE_WELCOME_TO_THE_WOLD_HARD_COVER,
  INPUT_NAME_CLOTH_COLOR,
  INPUT_NAME_COVER_IMAGE,
  INPUT_NAME_FREKLES,
  INPUT_NAME_HAIR_COLOR,
  INPUT_NAME_HAIR_STYLE,
  INPUT_NAME_KIDS_BIRTHDAY,
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_LANGUAGE,
  INPUT_NAME_P1_HAIR_COLOR,
  INPUT_NAME_P1_SKIN_COLOR,
  INPUT_NAME_P2_HAIR_COLOR,
  INPUT_NAME_P2_SKIN_COLOR,
  INPUT_NAME_PARENTS,
  INPUT_NAME_SKIN_COLOR,
  INPUT_VALUE_CLOTH_COLOR_BLUE,
  INPUT_VALUE_CLOTH_COLOR_GREEN,
  INPUT_VALUE_CLOTH_COLOR_RED,
  INPUT_VALUE_CLOTH_COLOR_YELLOW,
  INPUT_VALUE_ENGLISH,
  INPUT_VALUE_HAIR_COLOR_BLACK,
  INPUT_VALUE_HAIR_COLOR_BLONDE,
  INPUT_VALUE_HAIR_COLOR_BROWN,
  INPUT_VALUE_HAIR_COLOR_RED,
  INPUT_VALUE_HAIR_STYLE_CURL,
  INPUT_VALUE_HAIR_STYLE_STRAIGHT,
  INPUT_VALUE_JAPANESE,
  INPUT_VALUE_PARENTS_DAD,
  INPUT_VALUE_PARENTS_DAD_AND_DAD,
  INPUT_VALUE_PARENTS_MOM,
  INPUT_VALUE_PARENTS_MOM_AND_DAD,
  INPUT_VALUE_PARENTS_MOM_AND_MOM,
  INPUT_VALUE_SKIN_COLOR_BLACK,
  INPUT_VALUE_SKIN_COLOR_BROWN,
  INPUT_VALUE_SKIN_COLOR_NUTMEG,
  INPUT_VALUE_SKIN_COLOR_WHITE,
} from '@shared/book/constants';
import {
  getBookNameEnValidatorList,
  getBookNameValidatorList,
} from 'src/app/validators';
import {
  GET_BOOK_NAME_ERROR,
  GET_BOOK_NAME_ERROR_EN,
} from 'src/app/validators.constants';
import type {
  TClothColor,
  THairColor,
  THairStyle,
  TLanguage,
  TParents,
  TSkinColor,
} from '@shared/book/interfaces';
import { languageArr } from '@shared/book/data';
import enUS from '@shared/translations/en-US.json';
import { ALIAS_TO_NAME_MAP } from '@shared/book/maps';
import type { IBook } from './types';
import { getBookTextInputData } from './inputs/text/utils';
import {
  getBookRadioInputData,
  getBookRadioInputOptionData,
} from './inputs/radio/utils';
import { getBookPriceData } from './prices/utils';
import {
  getBookSelectInputData,
  getBookSelectInputOptionData,
} from './inputs/select/utils';
import { EBookBannerTextColor } from './banner/types';
import { EBookDescriptionTextItemType } from './desicription/types';
import { getBookDateInputData } from './inputs/date/utils';
import { WhosBirthdayTomorrowInputsSeason } from './WhosBirthdayTomorrow';
import { EBookSelectInputType } from './inputs/select/types';
import {
  getBookRadioColorInputData,
  getBookRadioColorInputOptionData,
} from './inputs/radioColor/utils';
import { getBookCheckboxInputData } from './inputs/checkbox/utils';
import {
  INPUT_NAME_RESERVED_CANVAS1,
  INPUT_NAME_RESERVED_CANVAS2,
} from './inputs/canvas/constants';
import type { IBookCanvasInputItem } from './inputs/canvas/types';
import {
  bookInputShowWhen,
  bookInputValidatorWhen,
  bookInputsToNameValueMap,
} from './inputs/utils';
import { getBookCanvasInputData } from './inputs/canvas/utils';
import {
  getBookRadioCoverInputData,
  getBookRadioCoverInputOptionData,
} from './inputs/radioCover/utils';
import { PROPERTY_PURPOSE_NEWBORN } from './properties/constants';

/**
 * * validatorWhen and showWhen are set inside IBook['inputs']
 * * by mutation funtions `bookInputValidatorWhen` and `bookInputShowWhenCheck`
 * * to make it possible to assign inputs between each other
 */

const WelcomeToTheWorldInputsKidsNameValidator = getBookNameValidatorList(8);
const WelcomeToTheWorldInputsKidsNameValidatorEn =
  getBookNameEnValidatorList(12);

export const WelcomeToTheWorldInputsKidsName = getBookTextInputData({
  label: 'First Name',
  // placeholder: 'ひらがな８文字まで',
  get placeholder() {
    return this.validator === WelcomeToTheWorldInputsKidsNameValidatorEn
      ? ('Up to 12 characters EN' as const)
      : ('Up to 8 characters' as const);
  },
  // value: '',
  name: INPUT_NAME_KIDS_NAME,
  // will be added in inputs array
  // validator: getBookNameValidatorList(8),
  get error() {
    return this.validator === WelcomeToTheWorldInputsKidsNameValidatorEn
      ? GET_BOOK_NAME_ERROR_EN(12)
      : GET_BOOK_NAME_ERROR(8);
  },
} as const);

export const WelcomeToTheWorldInputsKidsBirthday = getBookDateInputData({
  label: 'Date of Birth',
  placeholder: 'yyyy/mm/dd',
  // value: new Date(),
  max: new Date(),
  name: INPUT_NAME_KIDS_BIRTHDAY,
} as const);

export const WelcomeToTheWorldInputsParents = getBookSelectInputData({
  selectType: EBookSelectInputType.imageText,
  label: 'Parent(s)',
  placeholder: 'Please choose',
  options: [
    getBookSelectInputOptionData({
      label: 'Mom and Dad',
      imgSrc: 'assets/images/parents/mom-dad.png',
      value: INPUT_VALUE_PARENTS_MOM_AND_DAD,
    }),
    getBookSelectInputOptionData({
      label: 'Mom and Mom',
      imgSrc: 'assets/images/parents/mom-mom.png',
      value: INPUT_VALUE_PARENTS_MOM_AND_MOM,
    }),
    getBookSelectInputOptionData({
      label: 'Dad and Dad',
      imgSrc: 'assets/images/parents/dad-dad.png',
      value: INPUT_VALUE_PARENTS_DAD_AND_DAD,
    }),
    getBookSelectInputOptionData({
      label: 'Mom',
      imgSrc: 'assets/images/parents/mom.png',
      value: INPUT_VALUE_PARENTS_MOM,
    }),
    getBookSelectInputOptionData({
      label: 'Dad',
      imgSrc: 'assets/images/parents/dad.png',
      value: INPUT_VALUE_PARENTS_DAD,
    }),
  ],
  // value: '',
  name: INPUT_NAME_PARENTS,
});

const WelcomeToTheWorldInputsSkinColorOptions = [
  getBookRadioColorInputOptionData({
    color: '#FFEAD6',
    value: INPUT_VALUE_SKIN_COLOR_WHITE,
  }),
  getBookRadioColorInputOptionData({
    color: '#FAD5A2',
    value: INPUT_VALUE_SKIN_COLOR_NUTMEG,
  }),
  getBookRadioColorInputOptionData({
    color: '#CF9970',
    value: INPUT_VALUE_SKIN_COLOR_BROWN,
  }),
  getBookRadioColorInputOptionData({
    color: '#B28262',
    value: INPUT_VALUE_SKIN_COLOR_BLACK,
  }),
];

export const WelcomeToTheWorldInputsSkinColor = getBookRadioColorInputData({
  label: 'Skin color',
  options: WelcomeToTheWorldInputsSkinColorOptions,
  value: INPUT_VALUE_SKIN_COLOR_WHITE,
  name: INPUT_NAME_SKIN_COLOR,
});

export const WelcomeToTheWorldInputsFrekles = getBookCheckboxInputData({
  label: 'Freckles',
  name: INPUT_NAME_FREKLES,
  value: true,
} as const);

const WelcomeToTheWorldInputsHairColorOptions = [
  getBookRadioColorInputOptionData({
    color: '#F8D992',
    value: INPUT_VALUE_HAIR_COLOR_BLONDE,
  }),
  getBookRadioColorInputOptionData({
    color: '#CF744B',
    value: INPUT_VALUE_HAIR_COLOR_RED,
  }),
  getBookRadioColorInputOptionData({
    color: '#9F6D48',
    value: INPUT_VALUE_HAIR_COLOR_BROWN,
  }),
  getBookRadioColorInputOptionData({
    color: '#4D423B',
    value: INPUT_VALUE_HAIR_COLOR_BLACK,
  }),
];

export const WelcomeToTheWorldInputsHairColor = getBookRadioColorInputData({
  label: 'Hair color',
  options: WelcomeToTheWorldInputsHairColorOptions,
  value: INPUT_VALUE_HAIR_COLOR_BLONDE,
  name: INPUT_NAME_HAIR_COLOR,
});

export const WelcomeToTheWorldInputsHairStyle = getBookRadioInputData({
  label: 'Hair style',
  options: [
    getBookRadioInputOptionData({
      label: 'Straight',
      imgSrc: 'assets/images/wttw-canvas/form-icons/straight.png',
      value: INPUT_VALUE_HAIR_STYLE_STRAIGHT,
    }),
    getBookRadioInputOptionData({
      label: 'Curly',
      imgSrc: 'assets/images/wttw-canvas/form-icons/curl.png',
      value: INPUT_VALUE_HAIR_STYLE_CURL,
    }),
  ],
  value: INPUT_VALUE_HAIR_STYLE_STRAIGHT,
  name: INPUT_NAME_HAIR_STYLE,
});

export const WelcomeToTheWorldInputsClothColor = getBookRadioColorInputData({
  label: 'Pajama color',
  options: [
    getBookRadioColorInputOptionData({
      color: '#7ED0DA',
      value: INPUT_VALUE_CLOTH_COLOR_BLUE,
    }),
    getBookRadioColorInputOptionData({
      color: '#FBD269',
      value: INPUT_VALUE_CLOTH_COLOR_YELLOW,
    }),
    getBookRadioColorInputOptionData({
      color: '#F3A484',
      value: INPUT_VALUE_CLOTH_COLOR_RED,
    }),
    getBookRadioColorInputOptionData({
      color: '#C3CD59',
      value: INPUT_VALUE_CLOTH_COLOR_GREEN,
    }),
  ],
  value: INPUT_VALUE_CLOTH_COLOR_BLUE,
  name: INPUT_NAME_CLOTH_COLOR,
});

export const WelcomeToTheWorldInputsLanguage = getBookSelectInputData({
  label: 'Book Language',
  placeholder: '',
  options: languageArr.map((lang) =>
    getBookSelectInputOptionData({ label: enUS[lang], value: lang }),
  ),
  value: INPUT_VALUE_ENGLISH,
  name: INPUT_NAME_LANGUAGE,
} as const);

// * canvas common
const WelcomeToTheWorldInputsCanvasCommon = {
  width: 261,
  height: 444,
  // crop: {
  //   top: 15,
  //   right: 20,
  //   bottom: 18,
  //   left: 23,
  // },
  cropLtMd: {
    // top: 15,
    // left: 76,
    bottom: 135,
  },
  name: INPUT_NAME_RESERVED_CANVAS1,
} as const;

const WelcomeToTheWorldInputsCanvasInputs = [
  WelcomeToTheWorldInputsSkinColor,
  WelcomeToTheWorldInputsFrekles,
  WelcomeToTheWorldInputsHairColor,
  WelcomeToTheWorldInputsHairStyle,
  WelcomeToTheWorldInputsClothColor,
];

const WelcomeToTheWorldInputsCanvasItemsSkin: IBookCanvasInputItem[] = [
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S01.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_WHITE,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === false,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S05.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_WHITE,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === true,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S02.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_NUTMEG,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === false,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S06.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_NUTMEG,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === true,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S03.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BROWN,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === false,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S07.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BROWN,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === true,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S04.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BLACK,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === false,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S08.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BLACK,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === true,
      },
    ],
  },
];

const WelcomeToTheWorldInputsCanvasItemsCloth: IBookCanvasInputItem[] = [
  {
    imgSrc: 'assets/images/wttw-canvas/C/website-C01.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsClothColor,
        condition: (v: TClothColor) => v === INPUT_VALUE_CLOTH_COLOR_BLUE,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/C/website-C02.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsClothColor,
        condition: (v: TClothColor) => v === INPUT_VALUE_CLOTH_COLOR_YELLOW,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/C/website-C03.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsClothColor,
        condition: (v: TClothColor) => v === INPUT_VALUE_CLOTH_COLOR_RED,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/C/website-C04.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsClothColor,
        condition: (v: TClothColor) => v === INPUT_VALUE_CLOTH_COLOR_GREEN,
      },
    ],
  },
];

const WelcomeToTheWorldInputsCanvasItemsHair: IBookCanvasInputItem[] = [
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H01.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLONDE,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_STRAIGHT,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H05.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLONDE,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_CURL,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H02.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_RED,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_STRAIGHT,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H06.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_RED,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_CURL,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H03.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BROWN,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_STRAIGHT,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H07.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BROWN,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_CURL,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H04.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLACK,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_STRAIGHT,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H08.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLACK,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_CURL,
      },
    ],
  },
];

export const WelcomeToTheWorldInputsCanvas = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsCanvasCommon,
  label: 'Please choose child’s avatar.',
  inputs: WelcomeToTheWorldInputsCanvasInputs,
  items: [
    ...WelcomeToTheWorldInputsCanvasItemsSkin,
    ...WelcomeToTheWorldInputsCanvasItemsCloth,
    ...WelcomeToTheWorldInputsCanvasItemsHair,
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsCanvasInputs),
});

// * non related to initial book-form
export const WelcomeToTheWorldInputsCoverImage = getBookRadioCoverInputData({
  options: [
    getBookRadioCoverInputOptionData({
      label: 'Picnic',
      value: 'A',
      imgSrc:
        'assets/images/wttw-covers/bk3-cover-picnic-md-blue-EN-sample.png',
    }),
    getBookRadioCoverInputOptionData({
      label: 'Dreamy',
      value: 'B',
      imgSrc:
        'assets/images/wttw-covers/bk3-cover1-picnic-md-blue-EN-sample.png',
    }),
  ],
  // value: 'A',
  name: INPUT_NAME_COVER_IMAGE,
});

// * Season
export const WelcomeToTheWorldInputsSeason = WhosBirthdayTomorrowInputsSeason;

// * Parents
const WelcomeToTheWorldInputsParentsCanvasCommon = {
  width: 400,
  height: 348,
  noLtMdBackground: true,
  borderRadius: '10px',
  // crop: {
  //   top: 15,
  //   right: 20,
  //   bottom: 18,
  //   left: 23,
  // },
  // cropLtMd: {
  //   // top: 15,
  //   // left: 76,
  //   bottom: 135,
  // },
  // name: INPUT_NAME_RESERVED_CANVAS1,
} as const;

export const WelcomeToTheWorldInputsP1SkinColor = getBookRadioColorInputData({
  label: 'Skin color',
  options: WelcomeToTheWorldInputsSkinColorOptions,
  value: INPUT_VALUE_SKIN_COLOR_WHITE,
  name: INPUT_NAME_P1_SKIN_COLOR,
});

export const WelcomeToTheWorldInputsP2SkinColor = getBookRadioColorInputData({
  label: 'Skin color',
  options: WelcomeToTheWorldInputsSkinColorOptions,
  value: INPUT_VALUE_SKIN_COLOR_WHITE,
  name: INPUT_NAME_P2_SKIN_COLOR,
});

export const WelcomeToTheWorldInputsP1HairColor = getBookRadioColorInputData({
  label: 'Hair color',
  options: WelcomeToTheWorldInputsHairColorOptions,
  value: INPUT_VALUE_HAIR_COLOR_BLONDE,
  name: INPUT_NAME_P1_HAIR_COLOR,
});

export const WelcomeToTheWorldInputsP2HairColor = getBookRadioColorInputData({
  label: 'Hair color',
  options: WelcomeToTheWorldInputsHairColorOptions,
  value: INPUT_VALUE_HAIR_COLOR_BLONDE,
  name: INPUT_NAME_P2_HAIR_COLOR,
});

const WelcomeToTheWorldInputsParentsCanvas1Inputs = [
  WelcomeToTheWorldInputsP1SkinColor,
  WelcomeToTheWorldInputsP1HairColor,
];

const WelcomeToTheWorldInputsParentsCanvas2Inputs = [
  WelcomeToTheWorldInputsP2SkinColor,
  WelcomeToTheWorldInputsP2HairColor,
];

const getParentsCanvasItemsHair = (
  number: number,
  inputToCheck:
    | typeof WelcomeToTheWorldInputsP1HairColor
    | typeof WelcomeToTheWorldInputsP2HairColor,
): IBookCanvasInputItem[] => [
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}H/P${number}H-01.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLONDE,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}H/P${number}H-02.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_RED,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}H/P${number}H-03.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BROWN,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}H/P${number}H-04.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLACK,
      },
    ],
  },
];

const getParentsCanvasItemsSkin = (
  number: number,
  inputToCheck:
    | typeof WelcomeToTheWorldInputsP1SkinColor
    | typeof WelcomeToTheWorldInputsP2SkinColor,
): IBookCanvasInputItem[] => [
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}S/P${number}S-01.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_WHITE,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}S/P${number}S-02.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_NUTMEG,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}S/P${number}S-03.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BROWN,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}S/P${number}S-04.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BLACK,
      },
    ],
  },
];

export const WelcomeToTheWorldInputsParentsCanvasP1 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(1, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(1, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasP2 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas2Inputs,
  items: [
    ...getParentsCanvasItemsSkin(2, WelcomeToTheWorldInputsP2SkinColor),
    ...getParentsCanvasItemsHair(2, WelcomeToTheWorldInputsP2HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas2Inputs),
  name: INPUT_NAME_RESERVED_CANVAS2,
});

export const WelcomeToTheWorldInputsParentsCanvasP3 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(3, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(3, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasP4 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas2Inputs,
  items: [
    ...getParentsCanvasItemsSkin(4, WelcomeToTheWorldInputsP2SkinColor),
    ...getParentsCanvasItemsHair(4, WelcomeToTheWorldInputsP2HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas2Inputs),
  name: INPUT_NAME_RESERVED_CANVAS2,
});

export const WelcomeToTheWorldInputsParentsCanvasP5 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(5, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(5, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasP6 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas2Inputs,
  items: [
    ...getParentsCanvasItemsSkin(6, WelcomeToTheWorldInputsP2SkinColor),
    ...getParentsCanvasItemsHair(6, WelcomeToTheWorldInputsP2HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas2Inputs),
  name: INPUT_NAME_RESERVED_CANVAS2,
});

export const WelcomeToTheWorldInputsParentsCanvasP7 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(7, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(7, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasP8 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(8, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(8, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasArr = [
  WelcomeToTheWorldInputsParentsCanvasP1,
  WelcomeToTheWorldInputsParentsCanvasP2,
  WelcomeToTheWorldInputsParentsCanvasP3,
  WelcomeToTheWorldInputsParentsCanvasP4,
  WelcomeToTheWorldInputsParentsCanvasP5,
  WelcomeToTheWorldInputsParentsCanvasP6,
  WelcomeToTheWorldInputsParentsCanvasP7,
  WelcomeToTheWorldInputsParentsCanvasP8,
];

// * mutations
bookInputValidatorWhen(WelcomeToTheWorldInputsKidsName, [
  {
    validator: WelcomeToTheWorldInputsKidsNameValidatorEn,
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsLanguage,
        condition: (language: TLanguage) => language === INPUT_VALUE_ENGLISH,
      },
    ],
  },
  {
    validator: WelcomeToTheWorldInputsKidsNameValidator,
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsLanguage,
        condition: (language: TLanguage) => language === INPUT_VALUE_JAPANESE,
      },
    ],
  },
]);

// WelcomeToTheWorldInputsCanvas
bookInputShowWhen(
  WelcomeToTheWorldInputsCanvas,
  WelcomeToTheWorldInputsKidsName,
  // value is not passed when invalid
  (value?: string) => !!value,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsCanvas,
  WelcomeToTheWorldInputsKidsBirthday,
  // value is not passed when invalid
  (value?: Date) => !!value,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsCanvas,
  WelcomeToTheWorldInputsParents,
  // value is not passed when invalid
  (value?: string) => !!value,
);

// WelcomeToTheWorldInputsParentsCanvas
bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP1,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM_AND_DAD,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP2,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM_AND_DAD,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP3,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM_AND_MOM,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP4,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM_AND_MOM,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP5,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_DAD_AND_DAD,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP6,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_DAD_AND_DAD,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP7,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP8,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_DAD,
);

const WelcomeToTheWorld: IBook = {
  caption: ALIAS_TO_NAME_MAP[BOOK_ALIAS_WELCOME_TO_THE_WOLD],
  summary: [
    'Celebrate the arrival of your little one with a charming original book!',
  ],
  alias: BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  prices: [
    ['soft', getBookPriceData(BOOK_PRICE_WELCOME_TO_THE_WOLD)],
    ['hard', getBookPriceData(BOOK_PRICE_WELCOME_TO_THE_WOLD_HARD_COVER)],
  ],
  photos: [
    'assets/images/bk3-carousel01-EN.jpg',
    'assets/images/bk3-carousel02-EN.jpg',
    'assets/images/bk3-carousel03-EN.jpg',
    'assets/images/bk3-carousel04-EN.jpg',
  ],
  menuPhoto: 'assets/images/bk3-sample-EN.png',
  previewPhoto: 'assets/images/books03-EN.png',
  properties: {
    purpose: [PROPERTY_PURPOSE_NEWBORN],
    age: {
      start: 0,
      end: 3,
    },
  },
  notice: {
    caption: 'Baby Celebration Gift',
    description:
      'Welcome your newborn with this charming original book. Experience the warmth and love of a family as they welcome a new member into this world.',
    points: [
      {
        imgSrc: 'assets/images/welcome-notice-point-1.png',
        caption: "Best for 0~3 year old's",
      },
      {
        imgSrc: 'assets/images/welcome-notice-point-2.png',
        caption: 'Newborn Celebration Book',
      },
    ],
  },
  points: [
    {
      caption: 'What kind of customization can I make?',
      description:
        '<ul>' +
        "<li>Child's name</li>" +
        "<li>Child's story avatar</li>" +
        '<li>Parent(s) combination</li>' +
        '<li>Dedication message</li>' +
        "<li>Child's photo</li>" +
        '<li>Date and seasons of birth</li>' +
        '<li>Time of birth (clock)</li>' +
        '</ul>',
    },
    {
      caption: 'What is the story of the book？',
      description:
        'Open your eyes and journey to our wondrous world. Everything here welcomes you with open arms. Embark on your future adventures with those who love you most!',
    },
    {
      caption: 'About format and quality of the book.',
      description:
        'Every single book we make is print-to-order original picture book. We use thick, high-quality environment-friendly paper.\n' +
        '<ul>' +
        '<li><b>26 pages</b></li>' +
        '<li><b>11.7” x 8.3” (29.7cm x 21cm)</b></li>' +
        '<li><b>Printed in the USA</b></li>' +
        '</ul>',
    },
  ],
  inputs: [
    WelcomeToTheWorldInputsKidsName,
    WelcomeToTheWorldInputsKidsBirthday,
    WelcomeToTheWorldInputsParents,
    WelcomeToTheWorldInputsLanguage,
    WelcomeToTheWorldInputsCanvas,
  ],
  banner: {
    imgSrc: 'assets/images/bk3-banner-desktop-EN.jpg',
    imgSrcLtMd: 'assets/images/bk3-banner-mobile-EN.jpg',
    text: 'Each day brings new adventures.\nRevel in the magic and wonder of this world.',
    textColor: EBookBannerTextColor.dark,
  },
  description: [
    {
      imgSrc: 'assets/images/bk3-image01-EN.gif',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: 'Customize your family structure.',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'Who are the ones who love and wait for you the most? Is it mom and dad? Single mom or dad? Or two moms or two dads?',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'No matter who your parents are, they love you more than anything!',
        },
      ],
    },
    {
      imgSrc: 'assets/images/bk3-image02-EN.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: 'Include a picture of your newborn in this unique book.',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'Add your baby’s photo to the final page of this book.',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'The content and text are personalized with your baby’s name and birthday, making it a wonderful gift for a newborn.',
        },
      ],
    },
  ],
  review: {
    text: ['Such a lovely book to celebrate our girl’s birth. Thanks a lot!'],
  },
  reviews: [],
};

export default WelcomeToTheWorld;
