<div class="container">
  <div class="container__title">
    <h1 class="title">{{ book.caption | translate }}</h1>
  </div>
  <div class="container__info">
    <div class="info">
      <div class="info__gallery">
        <app-gallery [photos]="book.photos"></app-gallery>
      </div>
      <div class="info__description">
        <div class="description">
          <div class="description__section">
            <app-notice
              [alias]="book.alias"
              *ngIf="
                discountService.activeDiscountCampaignsObservable
                  | async as discountCampaign;
                else noticeInactiveDiscountCampaigns
              "
              [caption]="book.notice.caption"
              [description]="book.notice.description"
              [points]="book.notice.points"
              [prices]="book.prices"
              [discount]="getBooksDiscount(discountCampaign, book.alias)"
            ></app-notice>

            <ng-template #noticeInactiveDiscountCampaigns>
              <app-notice
                [alias]="book.alias"
                [caption]="book.notice.caption"
                [description]="book.notice.description"
                [points]="book.notice.points"
                [prices]="book.prices"
              ></app-notice>
            </ng-template>
          </div>
          <div
            *ngIf="book.review as review"
            class="description__section"
          >
            <section class="reviews">
              <div class="reviews__content">
                <p
                  *ngFor="let text of review.text"
                  class="reviews-text"
                >
                  {{ text }}
                </p>
              </div>
              <footer class="reviews__footer">
                <a
                  href="#reviews"
                  class="reviews-text"
                  >Read reviews</a
                >
                <img
                  src="assets/images/reviews-stars.png"
                  alt="★★★★★"
                  class="reviews-stars"
                />
              </footer>
            </section>
          </div>
          <div
            *ngIf="media.isActive('gt-lg') && book.points?.length"
            class="description__section"
          >
            <app-points [points]="book.points"></app-points>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container__form">
    <app-book-form
      class="form"
      [alias]="book.alias"
      [inputs]="book.inputs"
    ></app-book-form>
  </div>
  <div class="container__date">
    <app-section-message-date></app-section-message-date>
  </div>
  <div
    *ngIf="media.isActive('lt-xl') && book.points?.length"
    class="container__points"
  >
    <app-points [points]="book.points"></app-points>
  </div>
  <div
    *ngIf="book.banner"
    class="container__banner"
  >
    <app-banner
      [alias]="book.alias"
      [imgSrc]="book.banner.imgSrc"
      [imgSrcLtMd]="book.banner.imgSrcLtMd"
      [text]="book.banner.text"
      [textLtMd]="book.banner.textLtMd"
      [textColor]="book.banner.textColor"
      [textSize]="book.banner.textSize"
    ></app-banner>
  </div>
  <div
    *ngIf="book.description"
    class="container__description"
  >
    <app-description [description]="book.description"></app-description>
  </div>
  <div class="container__section-see-other">
    <app-section-see-other></app-section-see-other>
  </div>
  <div
    *ngIf="book.reviews"
    id="reviews"
    class="container__section-reviews"
  >
    <app-section-reviews-yotpo-book
      [alias]="book.alias"
      [caption]="book.caption"
      [prices]="book.prices"
      [photo]="book.previewPhoto"
    ></app-section-reviews-yotpo-book>
  </div>
  <div class="container__section-features">
    <app-section-features></app-section-features>
  </div>
  <div class="container__section-footer-banner">
    <app-section-banner-mermaid></app-section-banner-mermaid>
  </div>
  <div class="container__section-steps">
    <app-section-steps></app-section-steps>
  </div>
  <div class="container__section-faq">
    <app-section-faq [condensed]="true"></app-section-faq>
  </div>
</div>
