import type { OnDestroy, OnInit } from '@angular/core';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormPaymentComponent } from 'src/app/shared/form-payment/form-payment.component';
import type { IShipping } from 'src/app/data/shipping/types';
import { DiscountService } from 'src/app/services/discount.service';
import Subscriber from 'src/app/subscriber';
import type { BookInOrder } from '@shared/interfaces';
import type { TShippingMethod, TRegion } from '@shared/shipping/interfaces';
import type { IDiscountCampaign } from '@shared/discount/interfaces';
import { FormShippingAddressComponent } from 'app/shared/form-shipping-address/form-shipping-address.component';
import { getRegionByCode } from '@shared/shipping/utils';
import capitalize from 'lodash/capitalize';
import { getTotal } from 'app/shared/price/price.utils';
import { FormStripeCustomerComponent } from 'app/shared/form-stripe-customer/form-stripe-customer.component';
import type { IFormData } from '../../interfaces';

enum ESummary {
  email = 'E-mail address',
  address = 'Billing Address',
  shippingAddress = 'Shipping address',
  shippingMethod = 'Shipping Method',
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @Input() books!: BookInOrder[];

  @Output() dataChange: EventEmitter<IFormData>;

  @Input() disabledShippings!: TShippingMethod[];

  @Input() shippings?: IShipping[];

  @Input() set shipping(shipping: IShipping | undefined) {
    this.formShippingMethod = shipping?.id;
  }

  get shipping() {
    return this.shippings?.find(({ id }) => id === this.formShippingMethod);
  }

  @Input() isNextDisabled: boolean = false;

  @Output() nextClick = new EventEmitter<void>();

  @ViewChild(FormStripeCustomerComponent)
  public formCustomerInfoComponent!: FormStripeCustomerComponent;

  @ViewChild(FormShippingAddressComponent)
  public formShippingAddressComponent!: FormShippingAddressComponent;

  @ViewChild(FormPaymentComponent)
  public formPaymentComponent!: FormPaymentComponent;

  public formCustomerInfo: IFormData['formCustomerInfo'];

  public formShippingAddress: IFormData['formShippingAddress'];

  public formShippingAddressActive = true; // * checkbox

  public formShippingMethod?: IFormData['formShippingMethod'];

  public formPayment: IFormData['formPayment'];

  public printOnStaging: IFormData['printOnStaging'];

  public activeDiscountCampaign?: IDiscountCampaign;

  public readonly ESummary = ESummary;

  public readonly environment = environment;

  private readonly _sub = new Subscriber();

  // * summary getters
  get summaryEmail(): string {
    const form = this.formCustomerInfo;
    const arr: string[] = [];

    if (form) {
      arr.push(form.email);
    }

    return arr.join(' ');
  }

  get summaryAddress() {
    let str = '';
    const form = this.formCustomerInfo;

    if (form) {
      const {
        firstName,
        lastName,
        addressFirst,
        addressSecond,
        city,
        postalCode,
        country,
        state,
      } = form;
      const region = getRegionByCode(state);

      str = `${firstName} ${lastName}, ${addressFirst}${
        addressSecond ? `, ${addressSecond}` : ''
      }, ${postalCode}, ${city}, ${region.name}, ${country}
      `;
    }

    return str;
  }

  get summaryShippingAddress() {
    let str = '';
    const form = this.formShippingAddressActive
      ? this.formCustomerInfo
      : this.formShippingAddress;

    if (form) {
      const {
        firstName,
        lastName,
        addressFirst,
        addressSecond,
        city,
        postalCode,
        country,
        state,
      } = form;
      const region = getRegionByCode(state);

      str = `${firstName} ${lastName}, ${addressFirst}${
        addressSecond ? `, ${addressSecond}` : ''
      }, ${postalCode}, ${city}, ${region.name}, ${country}
      `;
    }

    return str;
  }

  get summaryShippingMethod(): string {
    const { shipping } = this;
    let str = '';

    if (shipping) {
      const { name, price, currency } = shipping;

      str = `${capitalize(name)} ꞏ ${getTotal(currency, price)}${
        currency === '$' ? ' USD' : ''
      }`;
    }
    return str;
  }

  // get summaryShow(): boolean {
  //   return (
  //     !!(
  //       this.summaryEmail ||
  //       this.summaryAddress ||
  //       this.summaryShippingAddress ||
  //       this.summaryShippingMethod
  //     ) && this.step !== 1
  //   );
  // }

  public readonly summaryShow = true;

  get region(): TRegion | undefined {
    const form = this.formShippingAddressActive
      ? this.formCustomerInfo
      : this.formShippingAddress;

    if (form) {
      return getRegionByCode(form.state);
    }

    return undefined;
  }

  constructor(private _discountService: DiscountService) {
    this.dataChange = new EventEmitter();
  }

  ngOnInit() {
    this._sub.push(
      this._discountService.activeDiscountCampaignsObservable.subscribe(
        (discountCampaign) => {
          this.activeDiscountCampaign = discountCampaign;
        },
      ),
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  public onChange() {
    const data: IFormData = {
      formCustomerInfo: this.formCustomerInfo,
      formShippingAddress: this.formShippingAddress,
      formShippingAddressActive: this.formShippingAddressActive,
      formShippingMethod: this.formShippingMethod,
      formPayment: this.formPayment,
      printOnStaging: this.printOnStaging,
    };

    this.dataChange.emit(data);
  }

  /**
   * filling the customer info form group with test data
   */
  public fillCustomerInfo(
    data: Partial<IFormData['formCustomerInfo']> = {},
  ): void {
    this.formCustomerInfoComponent.formGroup.setValue(
      Object.assign(this.formCustomerInfoComponent.formGroup.value || {}, data),
    );
  }
}
