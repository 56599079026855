import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import {
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  Input,
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import type { StripeAddressElement } from '@stripe/stripe-js';
import { MediaService } from 'app/services/media.service';
import { StripeService } from 'app/services/stripe.service';
import {
  addressFirstValidatorList,
  addressSecondValidatorList,
  cityValidatorList,
  countryValidatorList,
  emailValidatorList,
  firstNameValidatorList,
  lastNameValidatorList,
  phoneValidatorList,
  postalCodeValidatorList,
  stateValidatorList,
} from 'app/validators';
import { EMAIL_ERROR } from 'app/validators.constants';
import { BehaviorSubject } from 'rxjs';
import isEqual from 'lodash/isEqual';
import Subscriber from 'app/subscriber';
import type { IFormCustomerInfoData } from '../form-customer-info/form-customer-info.interfaces';
import {
  addressToStripeAddressElementDefaultValue,
  stripeAddressElementValueToAddress,
} from './form-stripe-customer.utils';

// const log = new Logger('FormStripeCustomerComponent');

@Component({
  selector: 'app-form-stripe-customer',
  templateUrl: './form-stripe-customer.component.html',
  styleUrls: ['./form-stripe-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormStripeCustomerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('formRef') formRef!: ElementRef<HTMLDivElement>;

  @Input() headerTitle = 'Customer Information (Billing Address)';

  @Input() headerTitleContent?: string;

  /** `undefined` when invalid */
  @Input() set data(data: IFormCustomerInfoData | undefined) {
    if (!isEqual(data, this.data)) {
      this._data$.next(data);
      this.dataChange.emit(data);

      if (data && !isEqual(data, this.formGroup.value)) {
        this.formGroup.setValue(data);
      }
    }
  }

  get data(): IFormCustomerInfoData | undefined {
    return this._data$.value;
  }

  private _data$ = new BehaviorSubject<IFormCustomerInfoData | undefined>(
    undefined,
  );

  @Output() dataChange = new EventEmitter<IFormCustomerInfoData | undefined>();

  public readonly EMAIL_ERROR = EMAIL_ERROR;

  public formGroup = new FormGroup({
    // name
    firstName: new FormControl('', firstNameValidatorList),
    lastName: new FormControl('', lastNameValidatorList),
    // address
    /// street
    addressFirst: new FormControl('', addressFirstValidatorList),
    /// apt
    addressSecond: new FormControl('', addressSecondValidatorList),
    /// city
    city: new FormControl('', cityValidatorList),
    // postal code
    postalCode: new FormControl('', postalCodeValidatorList),
    country: new FormControl('', countryValidatorList),
    state: new FormControl('', stateValidatorList),
    // phone
    phone: new FormControl('', phoneValidatorList),
    // email
    email: new FormControl('', emailValidatorList),
    // email2: new FormControl('', [createSecondFieldValidator('email')]),
  });

  private _addressElement?: StripeAddressElement;

  private _sub = new Subscriber();

  constructor(
    public media: MediaService,
    private _stripeService: StripeService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this._sub.push(
      this.formGroup.valueChanges.subscribe(
        (data: IFormCustomerInfoData): void => {
          this._cdr.detectChanges();

          if (this.formGroup.valid) {
            this.data = data;
            // this._addressElement?.update({
            //   defaultValues: addressToStripeAddressElementDefaultValue(data),
            // });
          } else {
            this.data = undefined;
          }
        },
      ),
    );
  }

  async ngAfterViewInit() {
    const elements = await this._stripeService.elements;
    const addressElement = elements.create('address', {
      mode: 'shipping',
      allowedCountries: ['US'],
      fields: {
        phone: 'always',
      },
      display: {
        name: 'split',
      },
      autocomplete: {
        mode: 'google_maps_api',
        apiKey: 'AIzaSyA--AVRC0CZ4uX8qOica5A6HN_4qmXI5nY',
      },
      defaultValues: addressToStripeAddressElementDefaultValue(
        this.formGroup.value,
      ),
    });

    addressElement.mount(this.formRef.nativeElement);

    addressElement.on('change', ({ complete, value }) => {
      const address = stripeAddressElementValueToAddress(value);

      // this.formGroup.patchValue(address);

      Object.entries(address).forEach(([key, _value]) => {
        const control = this.formGroup.controls[key];

        if (control.value !== _value) {
          control.setValue(_value);
          control.markAsDirty();
        }
      });

      // if (complete) {
      //   // log.info(data);
      //   this.formGroup.patchValue(stripeAddressElementValueToAddress(value));
      //   return;
      // }

      // this.dataChange.emit(undefined);
    });

    this._addressElement = addressElement;
  }

  async ngOnDestroy() {
    this._sub.unsubscribe();
    this._addressElement?.destroy();
  }

  public touch() {
    this.formGroup.markAllAsTouched();
    Object.values(this.formGroup.controls).forEach((control) => {
      control.markAsDirty();
    });
    this._addressElement?.getValue();
    this._cdr.detectChanges(); // detect changes to redraw the component
  }
}
