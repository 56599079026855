import { Injectable } from '@angular/core';
import type { IProduction } from '@shared/production/interfaces';
import {
  getEndOfCycleDate,
  getExpectedArrivalDate,
  getExpectedShippingDate,
} from '@shared/production/utils';
import { SHIPPING_METHOD_FAST } from '@shared/shipping/constants';
import type { TRegionName, TShippingMethod } from '@shared/shipping/interfaces';
import { getShippings } from 'app/data/shipping/utils';
import addDays from 'date-fns/addDays';
import type { Observable } from 'rxjs';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { FirebaseService } from '../firebase.service';
import {
  getProductionDocRef,
  getShortestShippingRegion,
} from './production.functions';

@Injectable({
  providedIn: 'root',
})
export class ProductionService {
  private readonly _data$ = new BehaviorSubject<IProduction | undefined>(
    undefined,
  );

  public readonly data$ = this._data$.pipe(
    filter((_): _ is IProduction => !!_),
  );

  public readonly cycles$ = this.data$.pipe(map((_) => _.cycles));

  public readonly exceptions$ = this.data$.pipe(map((_) => _.exceptions));

  public readonly shippingMethodsMap$ = this.data$.pipe(
    map((_) => _.shippingMethodsMap),
  );

  public readonly endOfCycleDate$ = this.data$.pipe(
    map((_) => getEndOfCycleDate(_.cycles)),
  );

  public readonly nextEndOfCycleDate$ = this.data$.pipe(
    switchMap((_) =>
      this.endOfCycleDate$.pipe(
        map((endOfCycleDate) =>
          getEndOfCycleDate(_.cycles, addDays(endOfCycleDate, 1)),
        ),
      ),
    ),
  );

  public readonly expectedShippingDate$ = this.data$.pipe(
    map((_) => getExpectedShippingDate(_.cycles, _.exceptions)),
  );

  public readonly defaultExpectedArrivalDate$ = this.data$.pipe(
    switchMap((_) =>
      this.expectedShippingDate$.pipe(
        map((expectedShippingDate) =>
          getExpectedArrivalDate(
            expectedShippingDate,
            getShortestShippingRegion(
              _.shippingMethodsMap[SHIPPING_METHOD_FAST],
            ),
            _.shippingMethodsMap[SHIPPING_METHOD_FAST],
            _.exceptions,
          ),
        ),
      ),
    ),
  );

  public readonly nextExpectedShippingDate$ = this.data$.pipe(
    switchMap((_) =>
      this.nextEndOfCycleDate$.pipe(
        map((nextEndOfCycleDate) =>
          getExpectedShippingDate(_.cycles, _.exceptions, nextEndOfCycleDate),
        ),
      ),
    ),
  );

  public readonly defaultNextExpectedArrivalDate$ = this.data$.pipe(
    switchMap((_) =>
      this.nextExpectedShippingDate$.pipe(
        map((nextExpectedShippingDate) =>
          getExpectedArrivalDate(
            nextExpectedShippingDate,
            getShortestShippingRegion(
              _.shippingMethodsMap[SHIPPING_METHOD_FAST],
            ),
            _.shippingMethodsMap[SHIPPING_METHOD_FAST],
            _.exceptions,
          ),
        ),
      ),
    ),
  );

  private readonly _dataDoc = getProductionDocRef(this._firebaseService.db);

  // public dataUnsubscribe?: Unsubscribe;

  constructor(private _firebaseService: FirebaseService) {
    this._init();
  }

  private _init() {
    /* this.dataUnsubscribe = */ this._dataDoc.onSnapshot((snap) => {
      const data = snap.data();

      if (!data) return;

      this._data$.next({
        cycles: data.cycles,
        exceptions: data.exceptions.map(({ start, end }) => ({
          start: start.toDate(),
          end: end.toDate(),
        })),
        shippingMethodsMap: data.shippingMethodsMap,
      });
    });
  }

  // public destroy() {
  //   this.dataUnsubscribe?.();
  // }

  public async getData() {
    return this.data$.pipe(take(1)).toPromise();
  }

  public getShippings$ = (
    args$: Observable<{ booksCount: number; regionName: TRegionName }>,
  ) =>
    combineLatest([args$, this.data$, this.expectedShippingDate$]).pipe(
      map(
        ([
          { booksCount, regionName },
          { shippingMethodsMap, exceptions },
          expectedShippingDate,
        ]) =>
          getShippings(
            booksCount,
            expectedShippingDate,
            regionName,
            shippingMethodsMap,
            exceptions,
          ),
      ),
    );

  public getExpectedArrivalDate$ = (
    shippingMethod$: Observable<TShippingMethod>,
    region$: Observable<TRegionName>,
    expectedShippingDate$ = this.expectedShippingDate$,
  ) =>
    combineLatest([
      shippingMethod$,
      region$,
      expectedShippingDate$,
      this.data$,
    ]).pipe(
      map(
        ([
          shippingMethod,
          region,
          expectedShippingDate,
          { shippingMethodsMap, exceptions },
        ]) =>
          getExpectedArrivalDate(
            expectedShippingDate,
            region,
            shippingMethodsMap[shippingMethod],
            exceptions,
          ),
      ),
    );
}
