export const NUMBERS_ERROR = 'Please use only numbers';

export const GET_BOOK_NAME_ERROR = (maxLength: number) =>
  `Maximum ${maxLength} hiragana letters allowed`;

export const GET_BOOK_NAME_ERROR_EN = (maxLength: number) =>
  `Maximum ${maxLength} alphabet letters allowed`;

export const BOOK_MESSAGE_MAX_ROW_LENGTH = 38;
export const BOOK_MESSAGE_MAX_ROW_LENGTH_EN = 56;
export const BOOK_MESSAGE_MAX_ROW_LENGTH_WTTW = 31;
export const BOOK_MESSAGE_MAX_ROW_LENGTH_WTTW_EN = 46;
export const BOOK_MESSAGE_MAX_ROW_LENGTH_WBT = 27;
export const BOOK_MESSAGE_MAX_ROW_LENGTH_WBT_EN = 38;
export const BOOK_MESSAGE_MAX_ROW_LENGTH_WIBC = 23;
export const BOOK_MESSAGE_MAX_ROW_LENGTH_SOGG = 25;

export const BOOK_MESSAGE_MAX_ROWS = 10;
export const BOOK_MESSAGE_MAX_ROWS_WTTW = 11;
export const BOOK_MESSAGE_MAX_ROWS_WBT = 8;
export const BOOK_MESSAGE_MAX_ROWS_WIBC = 9;
export const BOOK_MESSAGE_MAX_ROWS_SOGG = 11;

export const EMAIL_ERROR =
  'Please enter a valid e-mail format (name@example.com)';

export const EMAIL_MATCH_ERROR = 'E-mail does not match';

export const PASSWORD_MATCH_ERROR = 'Password does not match';

export const REQUIRED_ERROR = 'Required field';

export const NAME_MAX_LENGTH = 12;
export const FIRSTNAME_ERROR = REQUIRED_ERROR;
export const LASTNAME_ERROR = REQUIRED_ERROR;

// STREET ADDRESS
export const ADDRESS_1_ERROR = REQUIRED_ERROR;

export const ADDRESS_2_MAX_LENGTH = 22;
// APT
export const ADDRESS_2_ERROR = REQUIRED_ERROR;

export const ADDRESS_3_MAX_LENGTH = 22;
// CITY
export const ADDRESS_3_ERROR = REQUIRED_ERROR;

export const POSTAL_CODE_MAX_LENGTH = Infinity;
export const POSTAL_CODE_MIN_LENGTH = 5;
export const POSTAL_CODE_ERROR = 'Invalid postal code format. Ex. 99501.';

export const PHONE_MAX_LENGTH = Infinity;
export const PHONE_ERROR = 'Invalid phone number format';
