import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoModule } from 'src/app/components/logo/logo.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, LogoModule, AngularSvgIconModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
