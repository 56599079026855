import {
  BOOK_ALIAS_STARRY_DREAM,
  BOOK_PRICE_STARRY_DREAM,
  BOOK_PRICE_STARRY_DREAM_HARD_COVER,
  INPUT_NAME_CHARACTER,
  INPUT_NAME_GENDER,
  INPUT_NAME_KIDS_BIRTHDAY,
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_LANGUAGE,
  INPUT_VALUE_ENGLISH,
  INPUT_VALUE_GENDER_BOY,
  INPUT_VALUE_GENDER_GIRL,
  INPUT_VALUE_JAPANESE,
} from '@shared/book/constants';
import {
  getBookNameEnValidatorList,
  getBookNameValidatorList,
} from 'src/app/validators';
import {
  GET_BOOK_NAME_ERROR,
  GET_BOOK_NAME_ERROR_EN,
} from 'src/app/validators.constants';
import {
  ALIAS_TO_NAME_MAP,
  GENDER_TO_CHARACTER_SD_MAP,
} from '@shared/book/maps';
import type { TGender, TLanguage } from '@shared/book/interfaces';
import { languageArr } from '@shared/book/data';
import enUS from '@shared/translations/en-US.json';
import type { IBook } from './types';
import { getBookTextInputData } from './inputs/text/utils';
import {
  getBookRadioInputData,
  getBookRadioInputOptionData,
} from './inputs/radio/utils';
import { getBookPriceData } from './prices/utils';
import { EBookBannerTextColor } from './banner/types';
import { EBookDescriptionTextItemType } from './desicription/types';
import { getBookDateInputData } from './inputs/date/utils';
import {
  getBookRadioImageInputData,
  getBookRadioImageInputOptionData,
} from './inputs/radioImage/utils';
import { bookInputShowWhen, bookInputValidatorWhen } from './inputs/utils';
import {
  getBookSelectInputData,
  getBookSelectInputOptionData,
} from './inputs/select/utils';
import { PROPERTY_PURPOSE_ADVENTURE } from './properties/constants';

/**
 * * validatorWhen and showWhen are set inside IBook['inputs']
 * * by mutation funtions `bookInputValidatorWhen` and `bookInputShowWhenCheck`
 * * to make it possible to assign inputs between each other
 */

const StarryDreamInputsKidsNameValidator = getBookNameValidatorList(6);
const StarryDreamInputsKidsNameValidatorEn = getBookNameEnValidatorList(12);

export const StarryDreamInputsKidsName = getBookTextInputData({
  label: 'First Name',
  get placeholder() {
    return this.validator === StarryDreamInputsKidsNameValidatorEn
      ? ('Up to 12 characters EN' as const)
      : ('Up to 6 characters' as const);
  },
  // value: '',
  name: INPUT_NAME_KIDS_NAME,
  // will be added in inputs array
  // validator: getBookNameValidatorList(6),
  get error() {
    return this.validator === StarryDreamInputsKidsNameValidatorEn
      ? GET_BOOK_NAME_ERROR_EN(12)
      : GET_BOOK_NAME_ERROR(6);
  },
} as const);

export const StarryDreamInputsKidsBirthday = getBookDateInputData({
  label: 'Date of Birth',
  placeholder: 'yyyy/mm/dd',
  // value: new Date(),
  max: new Date(),
  name: INPUT_NAME_KIDS_BIRTHDAY,
});

export const StarryDreamInputsLanguage = getBookSelectInputData({
  label: 'Language',
  placeholder: '',
  options: languageArr.map((lang) =>
    getBookSelectInputOptionData({ label: enUS[lang], value: lang }),
  ),
  value: INPUT_VALUE_ENGLISH,
  name: INPUT_NAME_LANGUAGE,
} as const);

export const StarryDreamInputsGender = getBookRadioInputData({
  label: 'Gender',
  options: [
    getBookRadioInputOptionData({
      label: 'Boy',
      // imgSrc: 'assets/images/radio-boy.png',
      value: INPUT_VALUE_GENDER_BOY,
    }),
    getBookRadioInputOptionData({
      label: 'Girl',
      // imgSrc: 'assets/images/radio-girl.png',
      value: INPUT_VALUE_GENDER_GIRL,
    }),
  ],
  // value: INPUT_VALUE_GENDER_BOY,
  name: INPUT_NAME_GENDER,
});

export const StarryDreamInputsCharacterBoy = getBookRadioImageInputData({
  label: "Please choose child's avatar.",
  options: GENDER_TO_CHARACTER_SD_MAP[INPUT_VALUE_GENDER_BOY].map(
    (value, index) =>
      getBookRadioImageInputOptionData({
        imgSrc: `assets/images/sd-characters/boy/${index + 1}.png`,
        value,
      }),
  ),
  // value: '',
  name: INPUT_NAME_CHARACTER,
} as const);

export const StarryDreamInputsCharacterGirl = getBookRadioImageInputData({
  label: "Please choose child's avatar.",
  options: GENDER_TO_CHARACTER_SD_MAP[INPUT_VALUE_GENDER_GIRL].map(
    (value, index) =>
      getBookRadioImageInputOptionData({
        imgSrc: `assets/images/sd-characters/girl/${index + 1}.png`,
        value,
      }),
  ),
  // value: '',
  name: INPUT_NAME_CHARACTER,
} as const);

// * mutations
bookInputValidatorWhen(StarryDreamInputsKidsName, [
  {
    validator: StarryDreamInputsKidsNameValidatorEn,
    showWhen: [
      {
        inputToCheck: StarryDreamInputsLanguage,
        condition: (language: TLanguage) => language === INPUT_VALUE_ENGLISH,
      },
    ],
  },
  {
    validator: StarryDreamInputsKidsNameValidator,
    showWhen: [
      {
        inputToCheck: StarryDreamInputsLanguage,
        condition: (language: TLanguage) => language === INPUT_VALUE_JAPANESE,
      },
    ],
  },
]);

bookInputShowWhen(
  StarryDreamInputsCharacterBoy,
  StarryDreamInputsGender,
  (gender: TGender) => gender === INPUT_VALUE_GENDER_BOY,
);

bookInputShowWhen(
  StarryDreamInputsCharacterGirl,
  StarryDreamInputsGender,
  (gender: TGender) => gender === INPUT_VALUE_GENDER_GIRL,
);

const StaryDream: IBook = {
  caption: ALIAS_TO_NAME_MAP[BOOK_ALIAS_STARRY_DREAM],
  summary: [
    "Discover the stars and learn something new in a grand adventure with your child's zodiac sign in this original picture book!",
  ],
  alias: BOOK_ALIAS_STARRY_DREAM,
  prices: [
    ['soft', getBookPriceData(BOOK_PRICE_STARRY_DREAM)],
    ['hard', getBookPriceData(BOOK_PRICE_STARRY_DREAM_HARD_COVER)],
  ],
  photos: [
    'assets/images/bk1-carousel01-EN.jpg',
    'assets/images/bk1-carousel02-EN.jpg',
    'assets/images/bk1-carousel03-EN.jpg',
  ],
  menuPhoto: 'assets/images/bk1-sample-EN.png',
  previewPhoto: 'assets/images/books01-EN.png',
  properties: {
    purpose: [PROPERTY_PURPOSE_ADVENTURE],
    age: {
      start: 3,
      end: 8,
    },
  },
  notice: {
    caption: 'Mysterious Adventure',
    description: 'starry.dream.description',
    points: [
      {
        imgSrc: 'assets/images/starry-dream-notice-point-1.png',
        caption: "Best for 3~8 year old's",
      },
      {
        imgSrc: 'assets/images/starry-dream-notice-point-2.png',
        caption: 'Adventure Book',
      },
    ],
  },
  points: [
    {
      caption: 'What kind of customization can I make?',
      description:
        '<ul>' +
        "<li>Child's name</li>" +
        "<li>Child's story avatar</li>" +
        '<li>Accompanying zodiac character</li>' +
        '<li>Dedication message</li>' +
        "<li>Child's photo</li>" +
        '<li>Adventure locations (planets)</li>' +
        "<li>Star map from the child's birth time</li>" +
        '</ul>',
    },
    {
      caption: 'What is the story of the book?',
      description:
        'One night, a child receives a glittering invitation from their Zodiac sign to embark on an incredible adventure through space. In this dreamlike journey, the child can choose which planets to visit, including those of bravery, friendship, learning, creativity, passion, and compassion.',
    },
    {
      caption: 'About format and quality of the book.',
      description:
        'Every single book we make is print-to-order original picture book. We use thick, high-quality environment-friendly paper.\n' +
        '<ul>' +
        '<li><b>30 pages</b></li>' +
        '<li><b>11.7” x 8.3” (29.7cm x 21cm)</b></li>' +
        '<li><b>Printed in the USA</b></li>' +
        '</ul>',
    },
  ],
  inputs: [
    StarryDreamInputsKidsName,
    StarryDreamInputsKidsBirthday,
    StarryDreamInputsLanguage,
    StarryDreamInputsGender,
    StarryDreamInputsCharacterBoy,
    StarryDreamInputsCharacterGirl,
  ],
  banner: {
    imgSrc: 'assets/images/book1-banner-desktop.png',
    imgSrcLtMd: 'assets/images/book1-banner-mobile.png',
    text: 'The sky above us is a reminder that we are never truly apart.',
    textColor: EBookBannerTextColor.light,
  },
  description: [
    {
      imgSrc: 'assets/images/bk1-image01-EN.gif',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: 'Embark on a mysterious adventure guided by your zodiac sign!',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'Customize your journey through special planets and discover the wonderful encounters that await you.',
        },
      ],
    },
    {
      imgSrc: 'assets/images/bk1-image02-EN.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: 'Our personalized picture book features a custom star map based on the child’s birth date!',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'Our book takes children on a journey through the night sky and space, showing them that it’s not a scary place.',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'The story concludes with a happy child admiring a star map from their birth date and place.',
        },
      ],
    },
  ],
  reviews: [
    {
      photoImgSrc: 'assets/images/review4.jpg',
      userPhotoImgSrc: 'assets/images/review4-icon.png',
      userName: 'せなくん',
      text: [
        '息子のクリスマスプレゼントで購入🎄🎁',
        '息子が主人公で物語がはじまるとっても素敵な絵本💐',
        '絵も可愛くて息子も母もニコニコ︎︎︎︎︎😀',
        'パパとままもびっくりしてました。',
        '出産祝いにもいいなって思った🕊',
      ],
    },
    {
      photoImgSrc: 'assets/images/review1.png',
      userPhotoImgSrc: 'assets/images/review1user.png',
      userName: 'ゆのちゃん',
      text: [
        '「たのしかったです。',
        'えほんがめっちゃかわいいです。',
        'きにいりました。',
        'パパとままもびっくりしてました。',
        'ゆのちゃんのえほんがあるなんておもいもしませんでした。」',
      ],
    },
    {
      photoImgSrc: 'assets/images/review5.jpg',
      userPhotoImgSrc: 'assets/images/review5-icon.png',
      userName: 'はるまくん',
      text: [
        '届いた絵本をさっそく見せたらこんなにニコニコ😊',
        '読み聞かせたら、大好きなトリケラトプスがいて、大喜びでした。自分の誕生日のお話ってわかったかどうか・・。でもケーキの絵を見てハッピーバースデー歌ってました🎂',
        '素敵な絵本ありがとうございました。',
      ],
    },
    {
      photoImgSrc: 'assets/images/review2.png',
      userPhotoImgSrc: 'assets/images/review2user.png',
      userName: 'てんげんくん',
      text: [
        '自分が主人公になれて本の内容も自由に選べるオリジナル絵本📖♥️♥️かわいいっっ📖💓',
      ],
    },
    {
      photoImgSrc: 'assets/images/review3.png',
      userPhotoImgSrc: 'assets/images/review3user.png',
      userName: 'あづきちゃん',
      text: [
        '昨日、絵本届きました。',
        '娘が手に取った瞬間から大喜びでした。',
        '自分の名前と写真が入った、特別な本ということで、何度も何度も読んでいました。',
        'ありがとうございました☺️💕',
      ],
    },
  ],
  review: {
    text: [
      'It was really good. The pictures were cute and you could choose the content. The fact that the constellation on the day of birth was drawn in the starry sky seen from the window was really romantic!!',
    ],
  },
};

export default StaryDream;
