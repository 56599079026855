<section class="form">
  <header class="form__section form__section_header">
    <app-form-header
      [title]="headerTitle"
      [direction]="(media.isMobileObservable | async) ? 'column' : 'row'"
      >{{ headerTitleContent }}</app-form-header
    >
  </header>
  <div class="form__section form__section_content">
    <div class="section">
      <div class="section__input">
        <div #formRef></div>
      </div>
      <div class="section__input">
        <div
          class="input-wrap"
          [ngClass]="{
            'input-wrap_column': media.isMobileObservable | async
          }"
        >
          <div class="input-wrap__input">
            <div
              class="input"
              [ngClass]="{
                input_column: media.isMobileObservable | async
              }"
            >
              <div class="input__item">
                <app-input
                  type="email"
                  name="email"
                  placeholder="sample@hellobuki.com"
                  [formControl]="formGroup.controls['email']"
                  [error]="EMAIL_ERROR"
                  label="Email address"
                ></app-input>
              </div>
              <!-- <div class="input__item">
                <app-input
                  type="email"
                  name="email2"
                  placeholder="sample@hellobuki.com"
                  formControlName="email2"
                  [error]="EMAIL_MATCH_ERROR"
                  label="Email address (re-enter)"
                ></app-input>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
