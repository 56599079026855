import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { slideY } from 'src/app/animations';
import Subscriber from 'src/app/subscriber';
import isEqual from 'lodash/isEqual';
import { EFormPaymentType } from '@shared/interfaces';
import { MediaService } from 'app/services/media.service';
import { REQUIRED_ERROR } from 'app/validators.constants';
import type { IFormPaymentData } from './form-payment.interfaces';

@Component({
  selector: 'app-form-payment',
  templateUrl: './form-payment.component.html',
  styleUrls: ['./form-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideY()],
})
export class FormPaymentComponent implements OnInit, OnDestroy {
  @Input() isGift?: boolean;

  /** `undefined` when invalid */
  @Input() set data(data: IFormPaymentData | undefined) {
    if (!isEqual(data, this.data)) {
      if (data) {
        this.typeFormControl.setValue(data.type);
        this.formGroup.setValue(data);
      }

      this.dataChange.emit(data);
    }
  }

  get data() {
    return this.formGroup.value;
  }

  @Output() dataChange = new EventEmitter<IFormPaymentData | undefined>();

  @Input() set touchEventEmitter(e: EventEmitter<void>) {
    e.subscribe(() => {
      this.formGroup.markAllAsTouched();
    });
  }

  @Input() submitButtonDisabled: boolean = false;

  @Output() submitButtonClick = new EventEmitter<void>();

  public typeFormControl = new FormControl(EFormPaymentType.card, [
    Validators.required,
  ]);

  public cardFormGroup = new FormGroup({
    type: this.typeFormControl,
    terms: new FormControl(false, [Validators.requiredTrue]),
  });

  public readonly EFormPaymentType = EFormPaymentType;

  private readonly _sub = new Subscriber();

  get formGroup(): FormGroup {
    return this.cardFormGroup;
  }

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  constructor(public mediaService: MediaService) {}

  ngOnInit(): void {
    this._sub.push(
      this.cardFormGroup.valueChanges.subscribe((data: IFormPaymentData) => {
        this._onDataChange(data);
      }),
    );

    this.cardFormGroup.patchValue({ terms: true });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private _onDataChange(data: IFormPaymentData) {
    if (this.formGroup.valid) {
      this.dataChange.emit(data);
    } else {
      this.dataChange.emit(undefined);
    }
  }
}
