<div class="container">
  <div
    class="container__section container__section_test"
    *ngIf="!environment.production"
  >
    <app-form-test-buttons
      (customerInfoChange)="fillCustomerInfo($event)"
      (shippingAddressChange)="
        formShippingAddressComponent.active = false;
        formShippingAddressComponent.formGroup.setValue($event)
      "
      (paymentDataChange)="formPayment = $event"
      (printOnStagingChange)="printOnStaging = $event"
    ></app-form-test-buttons>
  </div>
  <!-- <div
    class="container__section container__section_summary"
    *ngIf="summaryShow"
  >
    <app-section-summary>
      <app-section-summary-item
        *ngIf="summaryEmail"
        [key]="ESummary.email"
        [value]="summaryEmail"
      ></app-section-summary-item>
      <app-section-summary-item
        *ngIf="summaryAddress"
        [key]="ESummary.address"
        [value]="summaryAddress"
      ></app-section-summary-item>
      <app-section-summary-item
        *ngIf="summaryShippingAddress"
        [key]="ESummary.shippingAddress"
        [value]="summaryShippingAddress"
      ></app-section-summary-item>
      <app-section-summary-item
        *ngIf="summaryShippingMethod"
        [key]="ESummary.shippingMethod"
        [value]="summaryShippingMethod"
      ></app-section-summary-item>
    </app-section-summary>
  </div> -->
  <div class="container__section">
    <app-form-stripe-customer
      [(data)]="formCustomerInfo"
      (dataChange)="onChange()"
    ></app-form-stripe-customer>
  </div>
  <div class="container__section">
    <app-form-shipping-address
      [(active)]="formShippingAddressActive"
      (activeChange)="onChange()"
      [(data)]="formShippingAddress"
      (dataChange)="onChange()"
    ></app-form-shipping-address>
  </div>
  <div class="container__section">
    <app-form-shipping-method
      *ngIf="region"
      [(shipping)]="shipping"
      [disabledShippings]="disabledShippings"
      [shippings]="shippings"
      (shippingChange)="onChange()"
      [region]="region"
    ></app-form-shipping-method>
  </div>
  <div class="container__section container__section_payment">
    <app-form-payment
      [(data)]="formPayment"
      (dataChange)="onChange()"
      (submitButtonClick)="nextClick.emit()"
      [submitButtonDisabled]="isNextDisabled"
    ></app-form-payment>
  </div>
</div>
