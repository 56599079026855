<div class="container">
  <div class="container__caption">
    <h3 class="caption">{{ caption | translate }}</h3>
    <div
      class="yotpo bottomLine"
      [attr.data-product-id]="bookExternalId"
    ></div>
  </div>
  <div class="container__description">
    <p class="description">{{ description | translate }}</p>
  </div>
  <div
    *ngIf="points?.length"
    class="container__points"
  >
    <div class="points">
      <div
        *ngFor="let point of points; let i = index"
        class="points__item"
        [ngClass]="{
          points__item_last: points.length === i + 1
        }"
      >
        <div class="point">
          <img
            class="point__img"
            [src]="point.imgSrc"
            alt="#"
          />
          <p
            class="point__txt"
            translate
          >
            {{ point.caption }}
          </p>
        </div>
      </div>
      <div class="points__item points__item_button">
        <app-price
          *ngIf="bookPrice"
          [currency]="bookPrice.currency"
          [price]="bookPrice.value"
          [discount]="discount"
          size="lg"
          [boldPrice]="true"
          [boldLabel]="true"
        ></app-price>
      </div>
    </div>
  </div>
</div>
