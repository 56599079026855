import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { IBookNotice } from 'src/app/data/books/notice/types';
import { TBookPrices } from 'src/app/data/books/prices/types';
import { TBookAlias } from '@shared/book/interfaces';
import { MediaService } from 'app/services/media.service';
import { ALIAS_TO_YOTPO_EXTERNAL_ID_MAP } from '@shared/book/maps';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoticeComponent {
  @Input() alias!: TBookAlias;

  @Input() caption!: IBookNotice['caption'];

  @Input() description!: IBookNotice['description'];

  @Input() points?: IBookNotice['points'];

  @Input() prices!: TBookPrices;

  @Input() discount?: string;

  @Input() buttonText?: string;

  @Output() buttonClick = new EventEmitter<void>();

  get bookPrice() {
    return this.prices[0]?.[1];
  }

  get bookExternalId() {
    return ALIAS_TO_YOTPO_EXTERNAL_ID_MAP[this.alias];
  }

  constructor(public media: MediaService) {}

  onButtonClick() {
    this.buttonClick.emit();
  }
}
