import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { IShipping } from 'src/app/data/shipping/types';
import { getShippingDiscount } from '@shared/discount/utils';
import { FEToBEShipping } from 'src/app/functions';
import { DiscountService } from 'src/app/services/discount.service';
import { ProductionService } from 'app/services/production.service/production.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import type { TShippingMethod } from '@shared/shipping/interfaces';
import { TRegion } from '@shared/shipping/interfaces';
import { filter, map } from 'rxjs/operators';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { PRODUCTION_TIME_ZONE } from '@shared/production/constants';

@Component({
  selector: 'app-form-shipping-method',
  templateUrl: './form-shipping-method.component.html',
  styleUrls: ['./form-shipping-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormShippingMethodComponent {
  @Input() set region(r: TRegion) {
    this.region$.next(r);
  }

  @Input() disabledShippings!: TShippingMethod[];

  @Input() shippings?: IShipping[];

  @Input() set shipping(shipping: IShipping | undefined) {
    if (shipping && this.shipping !== shipping) {
      this._shipping$.next(shipping);
      setTimeout(() => this.shippingChange.emit(shipping));
    }
  }

  get shipping(): IShipping | undefined {
    return this._shipping$.value;
  }

  @Output() shippingChange = new EventEmitter<IShipping>();

  private _shipping$ = new BehaviorSubject<IShipping | undefined>(undefined);

  public readonly FEToBEShipping = FEToBEShipping;

  public readonly getShippingDiscount = getShippingDiscount;

  public readonly region$ = new ReplaySubject<TRegion>(1);

  public readonly expectedArrivalDate$ = this._productionService
    .getExpectedArrivalDate$(
      this._shipping$.pipe(
        filter((s): s is IShipping => !!s),
        map((s) => s.id),
      ),
      this.region$.pipe(map((r) => r.name)),
    )
    .pipe(
      map(([date]) =>
        formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd'),
      ),
    );

  public readonly endOfCycleDate$ =
    this._productionService.endOfCycleDate$.pipe(
      map((date) => formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd')),
    );

  // as toggle-cards works with numbers
  // so we should have the index too
  private _i = 0; // default shipping

  set i(i: number | undefined) {
    if (i !== this._i) {
      this._i = i || 0;
      this.shipping = this.shippings?.[i || 0];
    }
  }

  get i() {
    const { shipping, shippings } = this;

    return shipping && shippings?.map((s) => s.id).indexOf(shipping.id);
  }

  constructor(
    public discountService: DiscountService,
    private _productionService: ProductionService,
  ) {}

  checkDisabled(shipping: IShipping): boolean {
    return this.disabledShippings.indexOf(shipping.id) > -1;
  }
}
