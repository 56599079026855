import type { IAddress } from '@shared/interfaces';
import { isCountryCode, isRegionCode } from '@shared/shipping/utils';
import type {
  StripeAddressElementChangeEvent,
  StripeAddressElementOptions,
} from '@stripe/stripe-js';

export const stripeAddressElementValueToAddress = ({
  name,
  firstName,
  lastName,
  address: { line1, line2, city, postal_code, country, state },
  phone,
}: StripeAddressElementChangeEvent['value']): Partial<IAddress> => {
  const address: Partial<IAddress> = {};

  if (isCountryCode(country)) {
    address.country = country;
  }

  if (isRegionCode(state)) {
    address.state = state;
  }

  // eslint-disable-next-line no-param-reassign
  // firstName = firstName || name.split(' ')[0];

  // if (firstName) {
  address.firstName = firstName || '';
  // }

  // eslint-disable-next-line no-param-reassign
  // lastName = lastName || name.split(' ')[1];

  // if (lastName) {
  address.lastName = lastName || '';
  // }

  // if (line1) {
  address.addressFirst = line1 || '';
  // }

  // if (line2) {
  address.addressSecond = line2 || '';
  // }

  // if (city) {
  address.city = city || '';
  // }

  // if (postal_code) {
  address.postalCode = postal_code || '';
  // }

  // if (phone) {
  address.phone = phone || '';
  // }

  return address;
};

export const addressToStripeAddressElementDefaultValue = ({
  firstName,
  lastName,
  addressFirst,
  addressSecond,
  city,
  postalCode,
  country,
  state,
  phone,
}: Partial<IAddress>): StripeAddressElementOptions['defaultValues'] => ({
  firstName: firstName ?? null,
  lastName: lastName ?? null,
  address: {
    line1: addressFirst ?? null,
    line2: addressSecond ?? null,
    city: city ?? null,
    state: state ?? null,
    postal_code: postalCode ?? null,
    country: country ?? null,
  },
  phone: phone ?? null,
});
